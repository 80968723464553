import { createI18n } from 'vue-i18n/index';

const messages = {
  en: {
    signInTitle: 'Sign In to AlHisba Admin',
    signInAppraiser: 'Sign In Appraisers',
    signInBanks: 'Sign In Banks',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    continue: 'Continue',
    alternativeLocale: 'AlHisba is also offered in: {0}',
    alternativeLocaleVal: 'العربية',
    pleaseWait: 'Please wait...',
    tryAgainExcl: 'Try again!',
    searchAdmins: 'Search Admins',
    addNewAdmin: 'Add New Admin',
    addNewAuction: 'Add New Auction',
    addNewAppraiser: 'Add New Appraiser',
    addNewBank: 'Add New Bank',
    addNewAnswer: 'Add New Answer',
    addNewLandSizeOption: 'Add New Land Size Option',
    addNewDirectionOption: 'Add New Direction Option',
    addNewSabahCitySpecsOption: 'Add New Sabah City Specs Option',
    addNewLocationSpecsOption: 'Add New Location Specs Option',
    addNewStreetOption: 'Add New Street Option',
    addNewLocationOption: 'Add New Location Option',
    addNewBuildingAgeOption: 'Add New Building Age Option',
    addNewFinishingQualityOption: 'Add New Finishing Quality Option',
    addNewFloorsOption: 'Add New Floors option',
    addNewCurbOption: 'Add New Curb Option',
    addNewPlotSpecsOption: 'Add New Plot Specs Option',
    addNewOrganizer: 'Add New Organizer',
    addNewLocation: 'Add New Location',
    addNewRole: 'Add New Role',
    addNewQualityRangeOption: 'Add New Quality Range Option',
    addNewAdType: 'Add New Ad Type',
    addNewProperty: 'Add New Property',
    addNewIveValue: 'Add New IVE Value',
    updateIreValue: 'Update  Ire Value',
    UpdateExistingValues: 'Update Existing Values',
    addNewFaq: 'Add New FAQ',
    addNewArea: 'Add New Area',
    addNewIVEAreaValue: 'Add New IVE Area Value',
    addNewPropertyTypes: 'Add New Property Type',
    addNewPropertyCategory: 'Add New Property Category',
    addNewFacingOption: 'Add New Facing Option',
    addNewBanner: 'Add New Banner',
    addNewBankRate: 'Add New Bank Rate',
    addNewQualityGroup: 'Add New Quality Group',
    addNewGroupQuestion: 'Add New Group Question',
    addNewIreArea: 'Add New Ire Area',
    addNewIreType: 'Add New Ire Type',
    addNewIreSize: 'Add New Ire Size',
    addNewIreFactorBathrooms: 'Add New Ire Factor Bathroom',
    addNewIreFactorsOptions: 'Add New Ire Factor Option',
    addNewIreFactorsQualitys: 'Add New Ire Factor Quality',
    addNewIreFactorsLocationSpecs: 'Add New Ire Factor Location Specs',
    addNewIreFactorGeneralspecs: 'Add New Ire Factor General specs',
    addNewIreFactorsPropertyService: 'Add New Ire Factor Propert Service',
    INVALID_EMAIL_PASSWORD: 'The username or password you entered is incorrect',
    INVALID_LONGITUDE: 'Longitude value is not valid',
    INVALID_LATITUDE: 'Latitude value is not valid',
    EMAIL_NOT_VALID: 'Email must be a valid email',
    EMAIL_IS_REQUIRED_FIELD: 'Email is a required field',
    PASSWORD_IS_REQUIRED_FIELD: 'Password is a required field',
    VERIFY_PASSWORD_IS_REQUIRED_FIELD: 'Verify Password is a required field',
    PASSWORDS_MUST_MATCH: 'Passwords must match',
    FULL_NAME_IS_REQUIRED_FIELD: 'Full Name is a required field',
    APPRAISER_NAME_IS_REQUIRED_FIELD: 'Appraiser Name is a required field',
    PHONE_IS_REQUIRED_FIELD: 'Phone is a required field',
    PHONE_NUMBER_IS_REQUIRED_FIELD: 'Phone Number is a required field',
    LOGO_IS_REQUIRED_FIELD: 'Logo is a required field',
    BANK_DETAILS_IS_REQUIRED_FIELD: 'Bank Account Details is a required field',
    PERSON_NAME_IS_REQUIRED_FIELD: 'Contact Person Name is a required field',
    PERSON_PHONE_IS_REQUIRED_FIELD: 'Contact Person Phone is a required field',
    EN_NAME_IS_REQUIRED_FIELD: 'English Name is a required field',
    EN_SIZE_IS_REQUIRED_FIELD: 'English Size is a required field',
    AR_SIZE_IS_REQUIRED_FIELD: 'Arabic Size is a required field',
    AR_NAME_IS_REQUIRED_FIELD: 'Arabic Name is a required field',
    SCORE_IS_REQUIRED_FIELD: 'Score is a required field',
    EN_VALUE_IS_REQUIRED_FIELD: 'English Value is a required field',
    AR_VALUE_IS_REQUIRED_FIELD: 'Arabic Value is a required field',
    FROM_RANGE_IS_REQUIRED_FIELD: 'From range is a required field',
    TO_RANGE_IS_REQUIRED_FIELD: 'To range is a required field',
    AR_QUESTION_IS_REQUIRED_FIELD: 'Arabic Question is a required field',
    AR_TITLE_IS_REQUIRED_FIELD: 'Arabic title is a required field',
    IMAGE_URL_IS_REQUIRED_FIELD: 'Image URL is a required field',
    URL_IS_REQUIRED_FIELD: 'URL is a required field',
    AR_ANSWER_IS_REQUIRED_FIELD: 'Arabic Answer is a required field',
    EN_QUESTION_IS_REQUIRED_FIELD: 'English Question is a required field',
    EN_ANSWER_IS_REQUIRED_FIELD: 'English Answer is a required field',
    EN_VENUE_IS_REQUIRED_FIELD: 'English Venue  is a required field',
    EN_TITLE_IS_REQUIRED_FIELD: 'English  title is a required field',
    LATITUDE_IS_REQUIRED_FIELD: 'Latitude is a required field',
    LONGITUDE_IS_REQUIRED_FIELD: 'Longitude  is a required field',
    AR_VENUE_IS_REQUIRED_FIELD: 'Arabic Venue  is a required field',
    AREA_VALUE_IS_REQUIRED_FIELD: 'Area Value is a required field',
    PRICE_VALUE_IS_REQUIRED_FIELD: 'Price Value is a required field',
    ACTIVE_STATUS_IS_REQUIRED_FIELD: 'Active Status is a required field',
    REJECTION_NOTE_IS_REQUIRED_FIELD: 'Rejection note is a required field',
    AUCTION_STATUS_IS_REQUIRED_FIELD: 'Auction Status is a required field',
    REQUEST_STATUS_IS_REQUIRED_FIELD: 'Request Status is a required field',
    DESCRIPTION_IS_REQUIRED_FIELD: 'Description is is a required field',
    ROLE_GROUP_IS_REQUIRED_FIELD: 'Role group is a required field',
    ADS_DURATION_IS_REQUIRED_FIELD: 'Ads duration is a required field ',
    WEIGHT_IS_REQUIRED_FIELD: 'Weight is a required field',
    GROUP_ID_IS_REQUIRED_FIELD: 'Group id is a required field',
    SORT_IS_REQUIRED_FIELD: 'Sort is a required field',
    ORGANIZER_IS_REQUIRED_FIELD: 'Organizer is a required field',
    EVENT_DATE_TIME_IS_REQUIRED_FIELD:
      'Event Date and Time is a required field',
    ORGANIZER_LOCATION__IS_REQUIRED_FIELD:
      'Organizer Location is a required field',
    BANNER_TYPE_IS_REQUIRED_FIELD: 'Banner Type is a required field',
    SCREEN_BANNER_IS_REQUIRED_FIELD: 'Screen banner is a required field',
    ACTION_BANNER_IS_REQUIRED_FIELD: 'Action banner is a required field',
    EXTERNAL_LINK_IS_REQUIRED_FIELD: 'External link is a required field',
    EXTERNAL_LINK_EN_IS_REQUIRED_FIELD: 'External link En is a required field',
    EXTERNAL_LINK_AR_IS_REQUIRED_FIELD: 'External link Ar is a required field',
    EXTERNAL_LINK_WEB_EN_IS_REQUIRED_FIELD:
      'External link Web En is a required field',
    EXTERNAL_LINK_WEB_AR_IS_REQUIRED_FIELD:
      'External link WebAr is a required field',
    ENTITY_ID_IS_REQUIRED_FIELD: 'Entity id is a required field',
    MERCHANT_IS_REQUIRED_FIELD: 'Merchant is a required field',
    ROLE_IS_REQUIRED_FIELD: 'Role is a required field',
    ROLE_NAME_IS_REQUIRED_FIELD: 'Role name  is a required field',
    NON_CLIENT_PERCENTAGE_IS_REQUIRED_FIELD:
      'Non client Percentage is a required field',
    BASE_PRICE_IS_REQUIRED_FIELD: 'Base price is a required field',
    ELECTRICITY_IS_REQUIRED_FIELD: 'electricity is a required field',
    PERCENT_IS_REQUIRED_FIELD: 'Percent is a required field',
    PASSWORD_AT_LEAST_ONE_NUMBER: 'Password must contain at least one number',
    PASSWORD_AT_LEAST_ONE_LOWER:
      'Password must contain at least one lowercase character',
    PASSWORD_AT_LEAST_ONE_UPPER:
      'Password must contain at least one uppercase character',
    PASSWORD_AT_LEAST_ONE_SPECIAL:
      'Password must contain at least one special character',
    SUCCESSFULLY_CREATED_ADMIN: 'Successfully created a new admin!',
    SUCCESSFULLY_CREATED_LOCATION: 'Successfully created a new location!',
    SUCCESSFULLY_CREATED_APPRAISER: 'Successfully created a new appraiser!',
    SUCCESSFULLY_CREATED_AD_PACKAGE: 'Successfully created a new ad package!',
    SUCCESSFULLY_CREATED_AUCTION: 'Successfully created a new auction!',
    SUCCESSFULLY_CREATED_ADS_SERVICE: 'Successfully created a new ads service!',
    SUCCESSFULLY_CREATED_IRE_AREA: 'Successfully created a new Ire area!',
    SUCCESSFULLY_CREATED_IRE_VALUE: 'Successfully created a new Ire Value',
    SUCCESSFULLY_UPDATED_APPRAISER: 'Successfully updated appraiser!',
    SUCCESSFULLY_UPDATED_AUCTION: 'Successfully updated auction!',
    SUCCESSFULLY_UPDATED_ADS_PACKAGE: 'Successfully updated ad package',
    SUCCESSFULLY_ADDED_AD_SERVICE: 'Successfully Added a new ad service',
    SUCCESSFULLY_UPDATED_BANNER: 'Successfully updated banner!',
    SUCCESSFULLY_ADDED_APPRAISER_BANKS: 'Successfully added appraiser banks!',
    SUCCESSFULLY_ADDED_AD_PACKAGE_SERVICES:
      'Successfully added ad package services!',
    SUCCESSFULLY_UPLOADED_AUCTION_VIDEO: 'Successfully uploaded auction video!',
    SUCCESSFULLY_UPLOAD_BANK_RATES: 'Successfully upload bank rates',
    SUCCESSFULLY_UPDATED_APPRAISER_BANKS:
      'Successfully updated appraiser banks!',
    SUCCESSFULLY_ADDED_APPRAISER_PRICES: 'Successfully added appraiser prices!',
    SUCCESSFULLY_CREATED_BANK: 'Successfully created a new bank!',
    SUCCESSFULLY_CREATED_ANSWER: 'Successfully created a new answer!',
    SUCCESSFULLY_CREATED_IVE_VALUE: 'Successfully created a new IVE value!',
    SUCCESSFULLY_UPDATED_GROUP_QUESTION: 'Successfully updated group question!',
    SUCCESSFULLY_UPDATED_STREET_OPTION: 'Successfully updated street option!',
    SUCCESSFULLY_CREATED_GROUP_QUESTION:
      'Successfully created a new group question!',
    SUCCESSFULLY_CREATED_STREET_OPTION:
      'Successfully created a new street option!',
    SUCCESSFULLY_CREATED_LAND_SIZE_OPTION:
      'Successfully created a new land size option!',
    SUCCESSFULLY_CREATED_PLOT_SPECS_OPTION:
      'Successfully created a new plot specs option!',
    SUCCESSFULLY_CREATED_LOCATION_SPECS_OPTION:
      'Successfully created a new location specs option!',
    SUCCESSFULLY_CREATED_DIRECTION_OPTION:
      'Successfully created a new direction option!',
    SUCCESSFULLY_CREATED_SABAH_CITY_SPECS_OPTION:
      'Successfully created a new sabah city specs option!',
    SUCCESSFULLY_CREATED_FACING_OPTION:
      'Successfully created a new facing option!',
    SUCCESSFULLY_CREATED_BUILDING_AGE_OPTION:
      'Successfully created a new building age option!',
    SUCCESSFULLY_CREATED_FLOOR_OPTION:
      'Successfully created a new floor option!',
    SUCCESSFULLY_CREATED_ORGANIZER: 'Successfully created a new organizer!',
    SUCCESSFULLY_CREATED_BANNER: 'Successfully created a new banner!',
    SUCCESSFULLY_CREATED_ROLE: 'Successfully created a new role!',
    SUCCESSFULLY_CREATED_BANK_RATE: 'Successfully created a new bank rate!',
    SUCCESSFULLY_CREATED_AD_TYPE: 'Successfully created a new ad type!',
    SUCCESSFULLY_CREATED_PROPERTY: 'Successfully created a new property!',
    SUCCESSFULLY_CREATED_FAQ: 'Successfully created a new FAQ!',
    SUCCESSFULLY_CREATED_AREA: 'Successfully created a new area!',
    SUCCESSFULLY_PULLED_DATA: 'Successfully pulled data!',
    SUCCESSFULLY_CREATED_NEWS: 'Successfully created a new news!',
    SUCCESSFULLY_CREATED_PROPERTY_TYPE:
      'Successfully created a new property type!',
    SUCCESSFULLY_CREATED_LOCATION_OPTION:
      'Successfully created a new location option!',
    SUCCESSFULLY_CREATED_CURB_OPTION: 'Successfully created a new curb!',
    SUCCESSFULLY_CREATED_FINISHING_QUALITY_OPTION:
      'Successfully created a new finishing quality option!',
    SUCCESSFULLY_CREATED_IRE_TYPE: 'Successfully created a new Ire Type',
    SUCCESSFULLY_CREATED_IRE_SIZE: 'Successfully created a new Ire Size',
    SUCCESSFULLY_CREATED_IRE_FACTOR_OPTIONS:
      'Successfully created a new Ire Options',
    SUCCESSFULLY_CREATED_IRE_FACTOR_QUALITY:
      'Successfully created a new Ire Quality',
    SUCCESSFULLY_CREATED_IRE_FACTOR_BATHROOMS:
      'Successfully created a new Ire Bathrooms',
    SUCCESSFULLY_CREATED_IRE_FACTOR_GENERAL_specs:
      'Successfully created a new Ire General specs',
    SUCCESSFULLY_CREATED_IRE_FACTOR_LOACATION_specs:
      'Successfully created a new Ire Location specs',
    SUCCESSFULLY_CREATED_IRE_FACTOR_PROPERTY_SERVICE:
      'Successfully created a new Ire Property Service',

    SUCCESSFULLY_CREATED_QUALITY_RANGE_OPTION:
      'Successfully created a new quality range option!',
    SUCCESSFULLY_CREATED_QUALITY_GROUP:
      'Successfully created a new quality group!',
    SUCCESSFULLY_UPDATED_QUALITY_GROUP: 'Successfully updated quality group!',
    SUCCESSFULLY_UPDATED_QUALITY_RANGE_OPTION:
      'Successfully updated quality range option!',
    SUCCESSFULLY_UPDATED_FLOOR_OPTION: 'uccessfully updated floor option!',
    SUCCESSFULLY_UPDATED_SEA_FRONT_OPTION:
      'Successfully updated sea front option!',
    SUCCESSFULLY_UPDATED_BACK_STREET_OPTION:
      'Successfully updated back street option!',
    SUCCESSFULLY_UPDATED_BANK_RATE_SETTINGS:
      'Successfully updated bank rate settings!',
    SUCCESSFULLY_UPDATED_BUILDING_AGE_OPTION:
      'Successfully updated building age option!',
    SUCCESSFULLY_UPDATED_FINISHING_QUALITY_OPTION:
      'Successfully updated finishing quality option!',
    SUCCESSFULLY_UPDATED_BANK_RATE: 'Successfully updated bank rate!',
    SUCCESSFULLY_UPDATED_ADMIN: 'Successfully updated admin!',
    SUCCESSFULLY_UPDATED_BANK: 'Successfully updated bank!',
    SUCCESSFULLY_UPDATED_ANSWER: 'Successfully updated answer!',
    SUCCESSFULLY_UPDATED_ORGANIZER: 'Successfully updated organizer!',
    SUCCESSFULLY_UPDATED_LOCATION: 'Successfully updated location!',
    SUCCESSFULLY_UPDATED_AD_TYPE: 'Successfully updated ad type!',
    SUCCESSFULLY_UPDATED_PROPERTY: 'Successfully updated property!',
    SUCCESSFULLY_UPDATED_FAQ: 'Successfully updated FAQ!',
    SUCCESSFULLY_UPDATED_AREA: 'Successfully updated area!',
    SUCCESSFULLY_UPDATED_PROPERTY_TYPE: 'Successfully updated property type!',
    SUCCESSFULLY_UPDATED_ABOUT_US: 'Successfully updated about us!',
    SUCCESSFULLY_UPDATED_TERMS_AND_CONDITIONS:
      'Successfully updated Terms and Conditions!',
    SUCCESSFULLY_UPDATED_GROUPS_WEIGHT: 'Successfully updated groups weight!',
    SUCCESSFULLY_UPDATED_QUESTIONS_WEIGHT:
      'Successfully updated questions weight!',
    SUCCESSFULLY_UPDATED_APPRAISAL_REQUEST:
      'Successfully updated appraisal request!',
    SUCCESSFULLY_UPDATED_ROLE: 'Successfully updated role!',
    SUCCESSFULLY_UPDATED_LAND_SIZE_OPTION:
      'Successfully updated land size option!',
    SUCCESSFULLY_UPDATED_DIRECTION_OPTION:
      'Successfully updated direction option!',
    SUCCESSFULLY_UPDATED_PLOT_SPECS_OPTION:
      'Successfully updated plot specs option!',
    SUCCESSFULLY_UPDATED_LOCATION_SPECS_OPTION:
      'Successfully updated location specs option!',
    SUCCESSFULLY_UPDATED_AS_DEFAULT: 'Successfully set as default!',
    SUCCESSFULLY_UPDATED_SABAH_CITY_SPECS_OPTION:
      'Successfully updated sabah city specs option!',
    SUCCESSFULLY_UPDATED_LOCATION_OPTION:
      'Successfully updated location option!',
    SUCCESSFULLY_UPDATED_ADS_PACKAGE_SERVICES:
      'Successfully updated ads package services',
    SUCCESSFULLY_UPDATED_AD_SERVICE: 'Successfully updated ad service',
    SUCCESSFULLY_UPDATED_HEADER_DESCRIPTION:
      'Successfully updated header description',
    SUCCESSFULLY_UPDATED_DISCLAIMER_DESCRIPTION:
      'Successfully updated disclaimer description',
    SUCCESSFULLY_ACTIVEATED: 'Successfully Activated',
    SUCCESSFULLY_INACTIVATED: 'Successfully Inactivated',
    SUCCESSFULLY_UPDATED_CURB_OPTION: 'Successfully updated curb option!',
    SUCCESSFULLY_UPDATED_IRE_TYPE: 'Successfully updated ire type!',
    SUCCESSFULLY_UPDATED_IRE_SIZE: 'Successfully updated ire size!',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_BATHROOM:
      'Successfully updated ire Bathroom',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_OPTION: 'Successfully updated ire Option',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_QUALITY: 'Successfully updated ire Quality',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_GENERAL_SPECS:
      'Successfully updated ire General specs',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_LOCATION_SPECS:
      'Successfully updated ire Location specs',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_PROPERTY_SERVICES:
      'Successfully updated ire Property Services',
    SUCCESSFULLY_UPDATED_IRE_VALUE: 'Successfully updated ire value!',
    PASSWORD_MIN_4: 'Password minimum length is 4 characters',
    PASSWORD_MAX_20: 'Password maximum length is 20 characters',
    SESSION_EXPIRED: 'Session Expired',
    SESSION_EXPIRED_TEXT:
      'Your session has expired. You will be redirected to the login page',
    selectPermissions: 'Select Permissions',
    permissionsCount: 'Up to {count} resources',
    attachmentsCount: '{count} attachments are available',
    permission: 'Permission',
    read: 'Read',
    create: 'Create',
    update: 'Update',
    delete: 'Delete',
    price: 'Price',
    images: 'Images',
    civilId: 'Civil ID',
    allowedFileTypes: 'Allowed file types: png, jpg, jpeg.',
    appraisalPropertyStatus: 'Property Status',
    appraisalPropertyTypes: 'Property Type',
    remarks: 'Remarks',
    selectGroup: 'Select a Group...',
    selectOrganizer: 'Select an Organizer...',
    selectPropertyType: 'Select Property Type...',
    selectLocation: 'Select a Location...',
    selectRole: 'Select a Role...',
    selectBanks: 'Select Banks...',
    selectFactorOptions: 'Select Factor Options...',
    selectServices: 'Select Services...',
    selectAreas: 'Selcet Areas...',
    selectStatus: 'Select a Status...',
    selectAuctionStatus: 'Select an Auction Status',
    selectAdsPackage: 'Select an Ads Package',
    SelectScreen: 'Select a Screen',
    selectDirection: 'Select a Direction',
    selectType: 'Select a Type',
    selectBannerType: 'select a banner type',
    selectpropertyStatus: 'Selecte a Property Status',
    roleGroupTooltip:
      'Available permissions vary based on the selected role group',
    clearAll: 'Clear All',
    basicInfo: 'Basic Info',
    propertyInfo: 'Property Info',
    backToList: 'Back to List',
    appraisalStatus0: 'Request Received',
    appraisalStatus1: 'In Review',
    appraisalStatus2: 'Rejected by Appraiser',
    appraisalStatus3: 'Accepted by Appraiser',
    appraisalStatus4: 'Report Delivered',
    appraisalRequestStatus: 'Request Status',
    idColumn: 'S.NO',
    venueEn: 'Venue Name - English',
    venueAr: 'Venue Name - Arabic',
    bankNameEn: 'Bank Name - English',
    streetNameEn: 'Street Name - English',
    organizerNameAr: 'Organizer Name - Arabic',
    organizerInfo: 'Organizer Information',
    bankNameEnLang: 'Bank Name in English Language',
    answerEnLang: 'Answer in English Language',
    answerScore: 'Answer Score',
    streetNameEnLang: 'Street Name in English Language',
    bankNameArLang: 'Bank Name in Arabic Language',
    answerArLang: 'Answer in Arabic Language',
    streetNameArLang: 'Street Name in Arabic Language',
    organizerPhone: 'Organizer Phone Number',
    organizerNameArLang: 'Organizer Name in Arabic Language',
    organizerNameEnLang: 'Organizer Name in English Language',
    venueNameArLang: 'Venue Name in Arabic Language',
    venueNameEnLang: 'Venue Name in English Language',
    bankNameAr: 'Bank Name - Arabic',
    streetNameAr: 'Street Name - Arabic',
    adTypeNameArLang: 'Ad Type Name in Arabic Language',
    adTypeNameEnLang: 'Ad Type Name in English Language',
    adTypeNameEn: 'Ad Type Name - English',
    adTypeNameAr: 'Ad Type Name - Arabic',
    propertyNameEn: 'Property Name - English',
    propertyNameAr: 'Property Name - Arabic',
    propertyTypeNameEn: 'Property Type Name - English',
    propertyTypeNameAr: 'Property Type Name - Arabic',
    propertyCategoryNameEn: 'Property Category Name - English',
    propertyCategoryNameAr: 'Property Category Name - Arabic',
    propertyNameArLang: 'Property Name in Arabic Language',
    propertyNameEnLang: 'Property Name in English Language',
    propertyCategoryNameArLang: 'Property Category Name in Arabic Language',
    propertyCategoryNameEnLang: 'Property Category Name in English Language',
    questionEnLang: 'Question in English Language',
    questionArLang: 'Question in Arabic Language',
    locationSpecsNameEn: 'Location Specs Name - English',
    locationSpecsNameAr: 'Location Specs Name - Arabic',
    locationSpecsOptionNameEnLang: 'Location Specs Name in English Language',
    locationSpecsOptionNameArLang: 'Location Specs Name in Arabic Language',
    facingNameEn: 'Facing Name - English',
    facingNameAr: 'Facing Name - Arabic',
    facingOptionNameEnLang: 'Facing Option Name in English Language',
    facingOptionNameArLang: 'Facing Option Name in Arabic Language',
    locationNameEn: 'Location Name - English',
    locationNameAr: 'Location Name - Arabic',
    locationOptionNameEnLang: 'Location Name Option in English Language',
    locationOptionNameArLang: 'Location Name Option in Arabic Language',
    curbNameEn: 'Curb Name - English',
    curbNameAr: 'Curb Name - Arabic',
    curbOptionNameEnLang: 'Curb OPtion Name in English Language',
    curbOptionNameArLang: 'Curb Option Name in Arabic  Language',
    faqQuestionEn: 'Question - English',
    faqAnswerEn: 'Answer - English',
    faqQuestionAr: 'Question - Arabic',
    faqAnswerAr: 'Answer - Arabic',
    areaNameEn: 'Area Name - English',
    areaNameAr: 'Area Name - Arabic',
    areaNameEnLang: 'Area Name in English Language',
    areaNameArLang: 'Area Name in Arabic Language',
    propertyTypeNameArLang: 'Property Type Name in Arabic Language',
    propertyTypeNameEnLang: 'Property Type Name in English Language',
    landSizeNameEnLang: 'Land Size in English Language',
    landSizeNameArLang: 'Land Size in Arabic Language',
    plotSpecsNameEnLang: 'Plot Specs Name in English Language',
    plotSpecsNameArLang: 'Plot Specs Name in Arabic Language',
    landSizeValue: 'Land Size - Value',
    plotSpecsNameEn: 'Plot Specs Name - English',
    plotSpecsNameAr: 'Plot Specs Name - Arabic',
    sabahSeaSpecsNameEn: 'Sabah City Specs - English',
    sabahSeaSpecsNameAr: 'Sabah City Specs - Arabic',
    sabahSeaSpecsNameEnLang: 'Sabah City Specs Name in English Lanuage',
    sabahSeaSpecsNameArLang: 'Sabah City Specs Name in Arabic Language',
    directionNameEn: 'Direction Name - English',
    directionNameAr: 'Direction Name - Arabic',
    directionOptionNameArLang: 'Direction Option Name in Arabic Language',
    directionOptionNameEnLang: 'Direction Option Name in English Language',
    buildingAgeValueEn: 'Building Age Value - English',
    buildingAgeValueAr: 'Building Age Value - Arabiic',
    buildingAgeOptionValueEnLang:
      'Building Age Option Value in English Language',
    buildingAgeOptionValueArLang:
      'Building Age Option Value in Arabic Language',
    quality: 'Quality',
    qualityValue: 'Quality Value',
    floorNameEn: 'Floor Name - English',
    floorNameAr: 'Floor Name - Arabic',
    floorOptionNameEnLang: 'Floor Option Name in English Language',
    floorOptionNameArLang: 'Floor Option Name in Arabic Language',
    seaFrontNameEn: 'Sea Front Name - English',
    seaFrontNameAr: 'Sea Front Name - Arabic',
    seaFrontOptionNameArLang: 'Sea Front Name Option in Arabic Language',
    seaFronOptionNameEnLang: 'Sea Front Name Option in English Language',
    backStreetNameEn: 'Back Street Name - English',
    backStreetNameAr: 'Back Street Name - Arabic',
    backStreetOptionNameEnLang: 'Back Street Option Name in English Language',
    backStreetOptionNameArLang: 'Back Street Option Name in Arabic Language',
    qualityNameEn: 'Quality Name - English',
    qualityNameAr: 'Quality Name - Arabic',
    qualityRangeNameOptionArLang:
      'Quality Range Name Option in Arabic Language',
    qualityRangeNameOptionEnLang:
      'Quality Range Name Option in English Language',
    qualityGroupNameEnLang: 'Quality Group Name in English Language',
    qualityGroupNameArLang: 'Quality Group Name in Arabic Language',
    questionNameEnLang: 'Question Name in English Language',
    questionNameArLang: 'Question Name in Arabic Language',
    imageUrl: 'Image URL',
    multiplicationFactors: 'Multiplication Factor',
    multiCurb: 'Multi Curb',
    multiSelection: 'Multi Selection',
    isSeaView: 'Sea View',
    customUpdatedAt: 'Custom Updated Date',
    pullData: 'Pull Data',
    url: 'URL',
    from: 'From',
    to: 'To',
    weight: 'Weight',
    appraisalRequestOverview: 'Appraisal Request Overview',
    adminUsersListing: 'Admin Users Listing',
    auctionsListing: 'Auctions Listing',
    appraisersUsersListing: 'Appraisers Users Listing',
    appraisersListing: 'Appraisers Listing',
    usersListing: 'Users Listing',
    banksListing: 'Banks Listing',
    answersListing: 'Answers Listing',
    bannerList: 'Banner Listing',
    ireAreasListing: 'Ire Areas Listing',
    ireTypesListing: 'Ire Types Listing',
    ireSizesListing: 'Ire Size Listing',
    IreFactorsBathroomsListing: 'Ire Factors Bathroom Listing',
    IreFactorsOptionsListing: 'Ire Factor Options Listing',
    IreFactorsQualitysListing: 'Ire Factor Qualitys Listing',
    IreFactorsGeneralspecsListing: 'Ire Factors General specs Listing',
    ireFactorsLocationSpecsListing: 'Ire Factors Location Specs Listing',
    IreFactorsPropertySeriveListing: 'Ire Factors Property Service Listing',
    streetFactorOptions: 'Street Factor Options',
    streetOptionsUpdating: 'Street Option Updating',
    facingFactorOptions: 'Facing Factor Options',
    facingOptionCreating: 'Facing Option Creating',
    facingOptionUpdating: 'Facing Option Updating',
    sabahCityFactorOptions: 'Sabah City Factor Options',
    sabahCityOptionCreating: 'Sabah City Option Creating',
    sabahCityOptionUpdating: 'Sabah City Option Updating',
    locationFactorOptions: 'Location Factor Options',
    locationOptionUpdating: 'Location Option Updating',
    locationOptionCreating: 'Location Option Creating',
    curbFactorOptions: 'Curb Factor Options',
    curbOptionUpdating: 'Curb Option Updating',
    curbOptionCreating: 'Curb Option Creating',
    buildingAgeFactorOptions: 'Building Age Factor Options',
    buildingAgeOptionUpdating: 'Building Age Option Updating',
    buildingAgeOptionCreating: 'Building Age Option Creating',
    finishingQualityFactorOptions: 'Finishing Quality Factor Options',
    finishingQualityOptionUpdating: 'Finishing Quality Option Updating',
    finishingQualityOptionCreating: 'Finishing Quality Option Creating',
    floorFactorOptions: 'Floor Factor Options',
    floorOptionsCreating: 'Floor Options Creating',
    floorOptionsUpdating: 'Floor Options Updating',
    seaFrontLenghsFactorOptions: 'Sea Front Lengths Factors Options',
    seaFrontLengthOptionUpdating: 'Sea Front Length Option Updating',
    backStreetLenghsFactorOptions: 'Back Street Lengths Factors Options',
    backStreetLengthOptionUpdating: 'Back Street Length Option Updating',
    organizersListing: 'Organizers Listing',
    locationsListing: 'Locations Listing',
    contactusListing: 'Contact us Listing',
    finishingqualityEn: 'Finishing Quality - English',
    finishingqualityAr: 'Finishing Quality - Arabic',
    finishingQualityOptionNameArLang:
      'Finishing Quality Option Name in Arabic Language',
    finishingQualityOptionNameEnLang:
      'Finishing Quality Option Name in English Language',
    appraisalsRequestsListing: 'Appraisals Requests Listing',
    appraisalsRequestsUpdating: 'Appraisals Requests Updating',
    appraiserName: 'Appraiser Name',
    adminUsersCreating: 'Admin Users Creating',
    appraisersUsersCreating: 'Appraisers Users Creating',
    auctionsCreating: 'Auctions Creating',
    bannerCreating: 'Add Banner Creating',
    auctionsEditing: 'Auctions Editing',
    appraisersUsersEditing: 'Appraisers Users Editing',
    savingPricesNote:
      'Saving appraiser details is required before proceeding to adding his prices.',
    savingBanksNote:
      'Saving appraiser details is required before proceeding to adding his banks.',
    savingAuctionAttachmentNote:
      'Saving auction details is required before proceeding to adding its attachments.',
    savingAuctionPropertiesNote:
      'Saving auction details is required before proceeding to adding its properties.',
    savingServiceNote:
      'Saving package details is required before proceeding to adding its services.',
    banksCreating: 'Banks Creating',
    answersCreating: 'Answers Creating',
    streetOptionsCreating: 'Street Options Creating',
    organizersCreating: 'Organizers Creating',
    locationsCreating: 'Locations Creating',
    adminUsersUpdating: 'Admin Users Updating',
    banksUpdating: 'Banks Updating',
    answersUpdating: 'Answers Updating',
    organizersUpdating: 'Organizers Updating',
    locationUpdating: 'Location Updating',
    appraisalRequestUpdating: 'Appraisal Request Updating',
    roleUpdating: 'Role Updating',
    rolesListing: 'Roles Listing',
    rolesCreating: 'Roles Creating',
    rolesUpdating: 'Roles Updating',
    adTypesListing: 'Ad Type Listing',
    adTypesUpdating: 'Ad Type Updating',
    adTypesCreating: 'Ad Type Creating',
    propertiesListing: 'Properties Listing',
    propertiesCreating: 'Properties Creating',
    propertiesUpdating: 'Properties Updating',
    bankRateUpdating: 'Bank Rate Updating',
    bankRatesListing: 'Bank Rates Listing',
    bankRateCreating: 'Bank Rate Creating',
    bankRatesLogReportsListing: 'bank Rates Log Reports Listing',
    importBankRate: 'Import Bank Rate',
    logReports: 'Log Reports',
    ireTypesUpdating: 'Ire Type Updating',
    ireSizesCreating: 'Ire Size Creating',
    ireFactorsOptionsCreating: 'Ire Factors Option Creating',
    ireFactorsGeneralspecsCreating: 'Ire Factors General specs Creating',
    ireFactorsLocationspecsCreating: 'Ire Factors Location specs Creating',
    ireFactorsBathroomCreating: 'Ire Factors Bathroom specs Creating',
    ireSizeUpdating: 'Ire Size Updating',
    ireFactorsbathroomsUpdating: 'Ire Factors Bathrooms Updating',
    ireFactorsPropertyServiceUpdating: 'Ire Factors Property Service Updating',
    ireFactorsOptionssUpdating: 'Ire Factors Options Updating',
    ireFactorGeneralspecsUpdating: 'Ire Factors General specs Updating',
    ireFActorsLocationspecsUpdating: 'Ire Factors Location specs Updating',
    faqsListing: 'FAQs Listing',
    faqsCreating: 'FAQs Creating',
    faqsUpdating: 'FAQs Updating',
    areasListing: 'Areas Listing',
    areasCreating: 'Areas Creating',
    areasUpdating: 'Areas Updating',
    areasIveValuesListing: 'Areas IVE Values Listing',
    areasIveValuesCreating: 'Areas IVE Values Creating',
    areasIveValuesUpdating: 'Areas IVE Valus Updating',
    newsCreating: 'News Creating',
    newsListing: 'News Listing',
    newsUpdating: 'News Updating',
    landSizeFactorOptions: 'Land Size Factor Options',
    PlotSpecsFactorOptions: 'Plot Specs Factor Options',
    landSizeOptionsUpdating: 'Land Size Options Updating',
    landSizeOptionsCreating: 'Land Size Options Creating',
    plotSpecsOptionsCreating: 'Plot Specs Options Creating',
    directionFactorOptions: 'Direction Factor Options',
    directionOptionsCreating: 'Direction Options Creating',
    directionOptionsUpdating: 'Direction Options Updating',
    propertyTypesListing: 'Property Types Listing',
    propertyTypesCreating: 'Property Types Creating',
    propertyTypesUpdating: 'Property Types Updating',
    propertyCategoryCreating: 'Property Category Creating',
    propertyCategoryUpdating: 'Property Category Updating',
    propertyCategoriesListing: 'Property Categories Listing',
    propertyCategoriesCreating: 'Property Categories Creating',
    propertyCategoriesUpdating: 'Property Categories Updating',
    locationSpecsFactorOptions: ' Ire Location Specs Factor Options',
    locationSpecsOptionsCreating: 'Ire Factor Location Specs Options Creating',
    ireTypesCreating: 'Ire Types Creating',
    ireFactorsbathroomsCreating: 'Ire Factor Bathrooms Creating',
    ireFactorsQualitysCreating: 'Ire Factor Quality Creating ',
    ireFactorsPropertyServiceCreating: 'Ire Factor Property Service Creating',
    locationSpecsOptionsUpdating: 'Location Specs Options Updating',
    rangesFinishingQuality: 'Ranges Finishing Quality',
    qualityRangeOptionCreating: 'Quality Range Option Creating',
    qualityRangeOptionUpdating: 'Quality Range Option Updating',
    qualitygroupOptionCreating: 'Quality Group Option Creating',
    GroupsWeightUpdating: 'Groups Weight Updating',
    QuestionsWeightUpdating: 'Questions Weight Updating',
    aboutUs: 'About Us',
    aboutUsUpdating: 'About Us Updating',
    termsAndConditions: 'Terms and Conditions',
    termsAndConditionsUpdating: 'Terms and Conditions Updating',
    description: 'Description',
    name: 'Name',
    roleName: 'Role Name',
    roleGroup: 'Role Group',
    shortDescription: 'Short Description',
    status: 'Status',
    actions: 'Actions',
    members: 'Members',
    auctions: 'Auctions',
    appraisals: 'Appraisals',
    ive: 'IVE',
    ire: 'IRE',
    assets: 'Assets',
    ads: 'Ads',
    active: 'Active',
    inactive: 'Inactive',
    createNewAdmin: 'Create New Admin',
    appraiserDetails: 'Appraiser Details',
    auctionDetails: 'Auction Details',
    auctionAttachments: 'Auction Attachments',
    createNewAppraiser: 'Create New Appraiser',
    createNewBank: 'Create New Bank',
    createNewAnswer: 'Create New Answer',
    createNewStreetOption: 'Create New Street Option',
    createNewGroup: 'Create New Group',
    createNewBuildingAgeOption: 'Create New Building Age Option',
    createNewFinishingQualityOption: 'Create New Finishing Quality Option',
    createNewFloorOption: 'Create New Floor Option',
    createNewLandSizeOption: 'Create New Land Size Option',
    createNewDirectionOption: 'Create New Direction Option',
    createNewSabahCitySpecsOption: 'Create New Sabah City Specs Option',
    createNewFacingOption: 'Create New Facing Optoin',
    createNewPlotSpecsOption: 'Create New Plot Specs Option',
    createNewLocationSpecsOption: 'Create New Location Specs Option',
    createNewOrganizer: 'Create New Organizer',
    createNewLocation: 'Create New Location',
    createNewAdType: 'Create New Ad Type',
    creatNewAdType: 'Create New Ad Type',
    createNewRole: 'Create New Role',
    createNewProperty: 'Create New Property',
    createNewPropertyCategory: 'Create New Property Category',
    createNewFaq: 'Create New FAQs',
    createNewArea: 'Create New Area',
    createNewIveValue: 'Create New IVE Value',
    createNewPropertyType: 'Create New Property Type',
    createNewLocationOption: 'Create New Location Option',
    createNewCurbOption: 'Create New Curb Option',
    createNewQualityRangeOption: 'Create New Quality Range Option',
    createNewIreArea: 'Create New Ire Area',
    createNewIreType: 'Create New Ire Type',
    createNewIreSize: 'Create New Ire Size',
    createNewIreFactorBathrooms: 'Create New Ire Factor Bathrooms',
    createNewIreFactorOptions: 'Create New Ire Factor Options',
    createNewIreFactorGeneralspecs: 'Create New Ire Factor General specs',
    createNewIreFactorsLocationspecs: 'Create New Ire Factor Location specs',
    createNewIreFactorsQualitys: 'Create New Ire Factor Quality',
    createNewIreFactorsPropertyService:
      'Create New Ire Factor Property Service',
    createNewBankRate: 'Create New Bank Rate',
    createNewNews: 'Create New News',
    createNewIreStreet: 'Create New Ire Street',

    fullName: 'Full Name',
    fullAdminName: 'Full Admin Name',
    adminEmail: 'Admin Email',
    newPassword: 'New Password',
    verifyPassword: 'Verify Password',
    discard: 'Discard',
    saveChanges: 'Save Changes',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
    updateAdmin: 'Update Admin',
    updateBank: 'Update Bank',
    updateAnswer: 'Update Answer',
    updateStreetOption: 'Update Street Option',
    updateBuildingAgeOption: 'Update Building Age Option',
    updateFinishingQualityOption: 'Update Finishing Quality Option',
    updateFloorOption: 'Update Floor Option',
    updateSeaFrontLengthOption: 'Update Sea Front Length Option',
    updateBackStreetLengthOption: 'Update Back Street Length Option',
    updateLocationOption: 'Update Location Option',
    updateCurbOption: 'Update Curb Option',
    updateOrganizer: 'Update Organizer',
    updateLocation: 'Update Location',
    updateAdType: 'Update Ad Type',
    updateRole: 'Update Role',
    updateProperty: 'Update Property',
    updateFaq: 'Update FAQs',
    contactUsOverview: 'Contact Us Overview',
    updateArea: 'Update Area',
    updateIveAreaValue: 'Update IVE Area Value',
    updatePropertyType: 'Update Property Type',
    updateAboutUs: 'Update About Us',
    updateTermsAndConditions: 'Update Terms and Conditions',
    updateLandSizeOption: 'Update Land Size Option',
    updateLocationSpecsOption: 'Update Location Specs Option',
    updatePlotSpecsOption: 'Update Plot Specs Option',
    UpdateloansFundsRequest: 'Update Loans Fund Request',
    updateGroupQuestion: 'Update Group Question',
    updateDirectionOption: 'Update Direction Option',
    updateSabahCitySpecsOption: 'Update Sabah City Specs Option',
    updateFacingOption: 'Update Facing Option',
    updateQualityRangeOption: 'Update Quality Range Option',
    updateQualityGroup: 'Update Quality Group',
    updateGroup: 'Update Group',
    updateIreType: 'Update Ire Type',
    updateIreFactorBathroom: 'Update Ire Factor Bathroom',
    updateIreFactorsOptions: 'Update Ire Factor Option',
    updateIreFactorsQualitys: 'Update Ire Factor Quality',
    updateIreLocationSpecs: 'Update Ire Location specs',
    updateIreFactorGeneralspecs: 'Update Ire Factor General specs',
    updateIreFactorsPropertyService: 'Update Ire Factor Property Service',
    updateBankRate: 'Update Bank Rate',
    updateQuestionsWeight: 'Update Questions Weight',
    updateGroupsWeight: 'Update Groups Weight',
    updatenews: 'Update News',
    bankRatesOverview: 'Bank Rates Overview',
    areYouSureQ: 'Are you sure?',
    youCantRevert: "You won't be able to revert this!",
    createdDate: 'Created Date',
    createdBy: 'Created By',
    confirm: 'Confirm',
    confirmDelete: 'Confirm delete',
    confirmInActive: ' Are you sure you want to inactivate it ?',
    confirmActive: 'Are you sure you want to activate it',
    confirmUserBlock: 'Yes, block user!',
    applicantInfo: 'Applicant Info',
    areaName: 'Area Name',
    basePrice: 'Basic Price',
    attachments: 'Attachments',
    baseSize: 'Basic Size',
    documents: 'Documents',
    other: 'Other',
    appraisalDetails: 'Appraisal Details',
    applicantName: 'Applicant Name',
    applicantEmail: 'Applicant Email',
    applicantMobile: 'Applicant Mobile',
    appraiserMobile: 'Appraiser Mobile',
    appraiserEmail: 'Appraiser Email',
    changeStatus: 'Change Status',
    changeAppraisalRequestStatus: 'Change Appraisal Request Status',
    additionalDetails: 'Additional Details',
    address: 'Address',
    bankAppraisal: 'Bank Appraisal',
    applicantType: 'Applicant Type',
    bankName: 'Bank Name',
    activeStatus: 'Active Status',
    rejectionNote: 'Rejection Note',
    areaSize: 'Area Size',
    englishName: 'English Name',
    arabicName: 'Arabic Name',
    arabicValue: 'Arabic Value',
    englishValue: 'English Value',
    arabicSize: 'Arabic Size',
    englishSize: 'English Size',
    englishQuestion: 'English Question',
    englishAnswer: 'English Answer',
    arabicQuestion: 'Arabic Question',
    arabicAnswer: 'Arabic Answer',
    textInArabic: 'Text in Arabic',
    textInEnglish: 'Text in English',
    phoneNumber: 'Phone Number',
    phone: 'Phone',
    accountType: 'Account Type',
    appraiserPhone: 'Appraiser Phone',
    cancel: 'Cancel',
    role: 'Role',
    approved: 'Approved',
    rejected: 'Rejected',
    contactPersonName: 'Contact Person Name',
    contactPersonPhone: 'Contact Person Phone',
    contactPerson: 'Contact Person',
    contactPhone: 'Contact Phone',
    bankAccountDetails: 'Bank Account Details',
    appraiserBanks: 'Appraiser Banks',
    appraiserPrices: 'Appraiser Prices',
    auctionProperties: 'Auction Properties',
    banks: 'Banks',
    noResultsFound: 'No results found',
    propertyType: 'Property Type',
    building: 'Building',
    percentage: 'Percentage',
    nonCustumerPercentage: 'Non Custumer Percentage',
    repaymentPeriod: 'Repament Period',
    nonBoubyanCustomer: 'Non Boubyan Customer',
    boubyanCustomer: 'Boubyan Customer',
    nonBoubyanCustomerPercentage: 'Non Boubyan Customer',
    boubyanCustomerPercentage: 'Boubyan Customer',
    land: 'Land',
    organiserName: 'Organizer Name',
    date: 'Date',
    time: 'Time',
    venue: 'Venue',
    type: 'Type',
    filterOptions: 'Filter Options',
    today: 'Today',
    historical: 'Historical',
    upcoming: 'Upcoming',
    reset: 'Reset',
    apply: 'Apply',
    filter: 'Filter',
    organizer: 'Organizer',
    dateAndTime: 'Date and Time',
    eventDateAndTime: 'Event Date and Time',
    location: 'Location',
    loanRequest: 'Loan Request',
    certifiedCheckRequest: 'Certified Check Request',
    block: 'Block',
    size: 'Size',
    propertyBlock: 'Property Block',
    propertyPlot: 'Property Plot',
    percentagePremiumPaid: 'Premium % Paid',
    propertySize: 'Property Size',
    startingPrice: 'Starting Price',
    salePrice: 'Sale Price',
    additionalInfoEn: 'Additional Information (EN)',
    exportIveArea: 'Export Ive Area',
    additionalInfoInEn: 'Additional Information in English',
    factorOptionIve: 'Factor Option Instant Value',
    additionalInfoInAr: 'Additional Information in Arabic',
    propertyAttachments: 'Property Attachments',
    additionalInfoAr: 'Additional Information (AR)',
    startingPricePerSqm: 'Starting Price/SQ. M.',
    startingPricePerSquareMeter: 'Starting Price Per Square Meter',
    salePricePerSqm: 'Sale Price/SQ. M.',
    salePricePerSquareMeter: 'Sale Price Per Square Meter',
    propertyStatus: 'Property Status',
    area: 'Area',
    plot: 'Plot',
    video: 'Video',
    auctionVideo: 'Auction Video',
    organizerLogo: 'Organizer Logo',
    logo: 'Logo',
    coordinates: 'Coordinates',
    latitude: 'Latitude',
    longitude: 'Longitude',
    loansFundsRequest: 'Loans Funds Request',
    loanFundsRequestOverview: 'Loans Funds Request Overview',
    propertyValue: 'Property Value',
    loanDuration: 'Loan Duration',
    annualProfit: 'AnnualProfit',
    totalPurchasePrice: 'Total Purchase Price',
    bankProfit: 'Bank Profit',
    downPayment: 'Down Payment',
    gracePeriod: 'Grace Period',
    priceFrom: 'Price From',
    priceTo: 'Price To',
    residentials: 'residentials',
    commercial: 'commercial ',
    propertySoldStatus: 'Property Sold Status',
    note: 'Note',
    noFactorOptionsLeft:
      'There are no options left for this factor to be added.',
    adsServices: 'Ads services',
    createNewAdService: 'Create new Ads Service',
    adsDuration: 'Ads duration',
    adsDurationDay: 'Ads duration (days)',
    arabicTitle: 'Arabic title',
    englishTitle: 'English title',
    score: 'Score',
    adServiceTitleArlang: 'Ads service title-Arabic ',
    adServiceTitleEnlang: 'Ads service title - English',
    adsServiceUpdating: 'Update Ads Service',
    adsCreating: 'Ad Service Creating',
    adsUpdate: 'Ad Service Update',
    adPackageAdsCreating: 'Package Ad Creating',
    adPackagesDetails: 'Package Details',
    adPackageTitleArlang: 'Package title - Arabic ',
    adPackageTitleEnlang: 'Package title - English ',
    adsPackagesUpdating: 'Update Ads Package',
    bannerUpdating: 'Banner Updating',
    adsPackages: 'Ads packages',
    createNewAdPackage: 'Create New Ads Package',
    createNewBanners: 'Create New Banner',
    packageServices: 'Package Services',
    bannerLocation: 'Banner Location',
    bannerAction: 'Banner Action',
    externalLink: 'External Link',
    externalLinkEn: 'External Link En ',
    externalLinkAr: 'External Link AR',
    externalLinkWebEn: 'External Web Link En',
    externalLinkWebAr: 'External Web Link Ar',
    bannerType: 'Banner Type',
    merchant: 'Merchant',
    entityId: 'entity ID',
    bannerUrl: 'Banner URL',
    bannerUrlEn: 'Banner URL En',
    bannerUrlAr: 'Banner URL Ar',
    bannerUrlWebEn: 'Banner URL Web En',
    bannerUrlWebAr: 'Banner URL Web Ar',
    bannerImage: 'Banner Image',
    bannerInfo: 'Banner Info',
    auctionStatus: 'Auction Status',
    appartmentSize: 'Appartment Size',
    ireSizesValues: 'Ire Sizes Values',
    Generalspecs: 'General specs',
    Locationspecs: 'Location specs',
    propertyService: 'Property Service',
    freeAd: 'Free AD',
    paidAd: 'Paid AD',
    numRooms: 'Number of Room',
    numBathrooms: 'Number of Bathrooms',
    adsPackagesTitle: 'Ad Package',
    additionalInfo: 'Additional Info',
    headerDescription: 'Header Description',
    headerDescriptionUpdating: 'Header Description Updating',
    disclaimer: 'Disclaimer',
    disclaimerUpdating: 'Disclaimer Updating',
    adminAccess: 'Admin access',
    actionDescription: 'Action description',
    timeStamp: 'Time stamp',
    exportLogsReports: 'Export Logs Reports',

    '-----------------': '-----------------',
    dashboard: 'Dashboard',
    layoutBuilder: 'Layout builder',
    craft: 'Crafted',
    pages: 'Pages',
    profile: 'Profile',
    profileOverview: 'Overview',
    projects: 'Projects',
    campaigns: 'Campaigns',
    connections: 'Connections',
    wizards: 'Wizards',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Account',
    accountOverview: 'Overview',
    settings: 'Settings',
    authentication: 'Authentication',
    basicFlow: 'Basic Flow',
    signIn: 'Sign-in',
    signUp: 'Sign-up',
    passwordReset: 'Password Reset',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Apps',
    chat: 'Chat',
    privateChat: 'Private Chat',
    groupChat: 'Group Chat',
    drawerChat: 'Drawer Chat',
    widgets: 'Widgets',
    widgetsLists: 'Lists',
    widgetsStatistics: 'Statistics',
    widgetsCharts: 'Charts',
    widgetsMixed: 'Mixed',
    widgetsTables: 'Tables',
    widgetsFeeds: 'Feeds',
    changelog: 'Changelog',
    docsAndComponents: 'Docs & Components',
    megaMenu: 'Mega Menu',
    exampleLink: 'Example link',
    modals: 'Modals',
    general: 'General',
    inviteFriends: 'Invite Friends',
    viewUsers: 'View Users',
    upgradePlan: 'Upgrade Plan',
    shareAndEarn: 'Share & Earn',
    forms: 'Forms',
    newTarget: 'New Target',
    newCard: 'New Card',
    newAddress: 'New Address',
    createAPIKey: 'Create API Key',
    twoFactorAuth: 'Two Factor Auth',
    createApp: 'Create App',
    createAccount: 'Create Account',
    documentation: 'Documentation',
    components: 'Components',
    resources: 'Resources',
    activity: 'Activity',
    customers: 'Customers',
    gettingStarted: 'Getting Started',
    customersListing: 'Customers Listing',
    customerDetails: 'Customers Details',
    calendarApp: 'Calendar',
    subscriptions: 'Subscriptions',
    getStarted: 'Getting Started',
    subscriptionList: 'Subscription List',
    addSubscription: 'Add Subscription',
    viewSubscription: 'View Subscription',
    sorting: 'Sorting',
    numberOfProperties: '# of Properties',
    updateCceGroup: 'Update CCE Group',
    createNewCceGroup: 'create New CCE Group',
    addNewCceGroup: 'Add New CCE Group',
    CceGroupsList: 'CCE Groups List',
    titleAr: 'Title Ar',
    titleEn: 'Title En',
    updateCceCategorie: 'Update CCE Categorie',
    createNewCceCategorie: 'create New CCE Categorie',
    addNewCceCategorie: 'Add New CCE Categorie',
    CceCategorieList: 'CCE Categories List',
    isDependQuality: 'is Depend Quality',
    savingSubCategoryOptionsNote:
      'Saving CCE sub Categorie details is required before proceeding to adding his Options.',
    cceCategory: 'CCE Category',
    cceCategoryTooltip: 'cce Category Tooltip',
    selectCceCategory: 'Select Cce Category',
    multiplier: 'Multiplier',
    defaultPrice: 'Default Price',
    sort: 'Sort',
    subCategoriesOptions: 'Sub Categories Options',
    updateCceSubCategorie: 'Update Cce Sub Categorie',
    CceSubCategoriesList: 'CCE Sub Categories List',
    addNewCceSubCategorie: 'Add New CCE Sub Categorie',
    categoryName: 'Category Name',
    SUCCESSFULLY_CREATED_CCE_GROUP: 'Successfully created a CCE group!',
    cceGroupsCreating: 'CCE Group Creating',
    SUCCESSFULLY_UPDATED_CCE_GROUP: 'Successfully update a CCE group!',
    cceGroupsListing: 'CCE Groups Listing',
    cceGroup: 'CCE Group',
    cceGroupTooltip: 'CCE Group Tooltip',
    selectCceGroup: 'Select Cce Group',
    SUCCESSFULLY_CREATED_CCE_CATEGORIE: 'Successfully created a CCE Categorie!',
    cceCategoriesCreating: 'CCE Categories Creating',
    cceCategoriesUpdating: 'CCE Categories Updating',
    SUCCESSFULLY_UPDATED_CCE_CATEGORIE: 'Successfully updated a CCE Categorie!',
    CceCategoriesList: 'CCE Categories List',
    cceCategoriesListing: 'CCE Categories Listing',
    groupName: 'Group Name',
    cceCategories: 'CCE Categories',
    cceCategoriesTooltip: 'CCE Categories Tooltip',
    cceSubCategoriesCreating: 'CCE Sub Categories Creating',
    cceSubCategoriesUpdating: 'CCE Sub Categories Updating',
    cceSubCategoriesListing: 'CCE Sub Categories Listing',
    cceGroups: 'CCE Groups',
    cceSubCategories: 'CCE Sub Categories',
    RegisteredTrades: 'Registered Trades',
    createNewRegisteredTrades: 'Create New Registered Trades',
    updateRegisteredTrades: 'Update Registered Trades',
    RegisteredTradesListing: 'Registered Trades Listing',
    pricePerM2: 'Price Per M2',
    notes: 'Notes',
    transactionDate: 'Transaction Date',
    RegisteredTradesScraping: 'Registered Trades Scraping',
    transactionNumber: 'Transaction Number',
    areas: 'Areas',
    descriptionAr: 'Description Arabic',
    descriptionEn: 'Description English',
    notesAr: 'Notes Arabic',
    notesEn: 'Notes English',
    registeredTradesUpdating: 'Registered Trades Updating',
    TRANSACTION_NUMBER_IS_REQUIRED_FIELD:
      'transaction number is required field',
    SIZE_IS_REQUIRED_FIELD: 'Size is required field',
    FACTOR_OPTION_IVE_IS_REQUIRED_FIELD:
      'Factor option value is required field',
    PRICE_IS_REQUIRED_FIELD: 'Price is required field',
    PRICE_PER_M2_IS_REQUIRED_FIELD: 'Price per m2 is required field',
    AREA_IS_REQUIRED_FIELD: 'Area is required field',
    STARTING_PRICE_IS_REQUIRED_FIELD: 'Starting price is required field',
    STARTING_PRICE_PER_SM_IS_REQUIRED_FIELD:
      'Starting price per square meter is required field',
    PROPERTY_TYPE_IS_REQUIRED_FIELD: 'Property type is required field',
    SUCCESSFULLY_UPDATED_REGISTERED_TRADE:
      'Successfully updated registered trade',
    SUCCESSFULLY_CREATED_REGISTERED_TRADE:
      'Successfully created registered trade',
    pulleRegisteredTradesScraping: 'Pull Registered Trades Scraping',
    selectDate: 'Select Date',
    SUCCESSFULLY_PULL_REGISTERED_TRADE_SCRAPING:
      'Successfully pull registered trade scraping',
    SUCCESSFULLY_UPDATED_REGISTERED_TRADE_SCRAPING:
      'Successfully updated registered trade scraping',
    SUCCESSFULLY_UPDATED_LOAN_FUNDS_REQUEST:
      'Successfully updated loan fund request',
    adsListing: 'Ads Listing',
    thumbnail: 'Thumbnail',
    id: 'S.NO',
    publishName: 'Publish Name',
    publishMobile: 'Publish Mobile',
    adsType: 'Ads Type',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    publishedAt: 'Published At',
    isPaid: 'Paid',
    attachmentImages: 'Ads Images',
    attachmentVideos: 'Ads Video',
    attachmentVideosAuction: 'Auction Video',
    docAttachment: 'Ads Documents',
    adsDetails: 'Ads Details',
    adOverview: 'Ad Overview',
    changeAdsRequestStatus: 'Change Ads Status',
    adsUpdating: 'Ads Updating',
    SUCCESSFULLY_UPDATED_ADS_REQUEST: 'Successfully Updated Ads Status',
    instantValueEstimatorPanel: 'Instant Value Estimator Panel',
    instantValueCloningPanel: 'Instant Value Cloning Panel',
    ValueSizePanel: ' Value Size Panel',
    ValueFactorBathroomPanel: ' Value Factor Bathroom Panel',
    ValueFactorOptionPanel: ' Value Factor Option Panel',
    ValueFactorGeneralspecsPanel: ' Value Factor General specs Panel',
    ValueFactorLocationspecsPanel: ' Value Factor Location specs Panel',
    ValueFactorQualityPanel: ' Value Factor Quality Panel',
    ValueFactorPropertyServicePanel: ' Value Factor Property Service Panel',
    qualityGroupsPanel: 'Quality Groups Panel',
    factors: 'Factors',
    totalValues: 'Total Values',
    factorOption: 'Factor Option',
    value: 'Value',
    addNewValue: 'Add New Value',
    addNewQuestion: 'Add New Question',
    landingEstimator: 'Land Estimator',
    landingEstimatorClone: 'Land Estimator Clone',
    FactorsBathroomValues: 'Factors Bathroom Values',
    FactorsOptionValues: 'Factors Option Values',
    factorQualitysValues: 'Factors Quality Values',
    areaCompare: 'Area Compare',
    areaClone: 'Area Clone',
    startComparingAreas: 'Start Comparing Areas',
    PHONE_MIN_8: 'minimum length is 8 Number',
    PHONE_MAX_11: 'maximum length is 11 Number',
    currentVideoUrl: 'Current Video URL',
    qualityGroupsLisitng: 'Quality Groups Listing',
    selected: 'Selected',
    approveSelected: 'Approve Selected',
    anotherCompare: 'Another Compare',
    setAsDefault: 'Set As Default',
    approveAllAds: 'Approve All Ads',
    confirmApproveAll: 'Are you sure you want to approve all items?',
    bulkUpdate: 'Bulk Update',
    deactivate: 'Deactivate',
    login: 'Login',
    logout: 'Logout',
    success: 'Success',
    failed: 'Failed',
    selectAction: 'Select a Action',
    expiryBanner: 'Expiry Banner',
    settingBankRate: 'settings Bank Rate',
    enableGracePeriod: 'Enable Grace Period',
    disableGracePeriod: 'Disable Grace Period',
    isRegisteredTrades: 'Registered Trades',
    isAd: 'Ads',
    isAuction: 'Auctions',
    SUCCESSFULLY_UPDATED_PROPERTY_CATEGORY:
      'Successfully Updated Property Category',
    SUCCESSFULLY_CREATED_PROPERTY_CATEGORY:
      'Successfully Created Property Category',
    selectPropertyCategory: 'Select Property Category',
    propertyCategory: 'Property Category',
    PROPERTY_CATEGORY_IS_REQUIRED_FIELD: 'Property Category is required field',
    SUCCESSFULLY_ADDED_IRE_SIZE_OPTIONS: 'Successfully Added IRE Size Options',
    SUCCESSFULLY_ADDED_IRE_PROPERTY_AGE_OPTIONS:
      'Successfully Added IRE Property Age Options',
    savingIreAvailableSizesNote: 'Saving IRE Available Sizes',
    isBaseSize: 'Is Base Size',
    IrePropertyAge: 'IRE Property Age',
    savingIreAvailablePropertyAgeNote: 'Saving IRE Available Property Age',
    ireAreasCreating: 'IRE Areas Creating',
    areasIreValuesUpdating: 'Areas IRE Values Updating',
    updateIreAreaValue: 'Update IRE Area Value',
    IreAvailableSizes: 'IRE Available Sizes',
    MUST_ADDED_IRE_BASE_SIZE_OPTIONS: 'Please Select IRE Base Size',
    ONE_IRE_BASE_SIZE_OPTIONS: 'Please Select Only One IRE Base Size',
    UserInfo: 'User Information',
    firstIncreasePercentage: 'First Increase Percentage',
    firstIncreasePercentageToolTip:
      'First Increase Percentage For GOOD Quality',
    secondIncreasePercentage: 'Second Increase Percentage',
    secondIncreasePercentageToolTip:
      'Second Increase Percentage For VERY GOOD Quality',
    decreasedPercentage: 'First Decreased Percentage',
    firstDecreasedPercentageToolTip:
      'First Decreased Percentage For 10-19 Property Age',
    secondDecreasedPercentage: 'Second Decreased Percentage',
    secondDecreasedPercentageToolTip:
      'Second Decreased Percentage For 20-30 Property Age',
    AREA_FIRST_INCR_IS_REQUIRED_FIELD: 'Area First Increment Is Required Field',
    AREA_SECOND_INCR_IS_REQUIRED_FIELD:
      'Area Second Increment Is Required Field',
    AREA_FIRST_DESC_IS_REQUIRED_FIELD:
      'Area First Decreasedcrement Is Required Field',
    AREA_SECOND_DESC_IS_REQUIRED_FIELD:
      'Area Second Decreasedcrement Is Required Field',
    SUCCESSFULLY_CREATED: 'Successfully Created',
    SUCCESSFULLY_UPDATED: 'Successfully Updated',
    updateIreQualities: 'Update Ire Qualities',
    ireQualitiesListing: 'Ire Qualities Listing',
    irePropertyTypeListing: 'ire Property Type Listing',
    updateIrePropertyType: 'update Ire Property Type',
    ireInvestmentPropertyAgeListing: 'ire Property Age',
    updateIrePropertyAge: 'update Ire Property Age',
    ireLocationsListing: 'ire Locations Listing',
    updateIreLocations: 'update Ire Locations',
    ireInvestmentConfigListing: 'Ire Investment Config Listing',
    updateIreInvestmentConfig: 'update Ire Investment Config',
    PERCENTAGE_IS_REQUIRED_FIELD: 'Percentage is required',
    NumOfRegisteredTrades: 'Number Of Registered Trades : ',
    askSeller: 'Ask Seller',
    priceNotAvailable: 'Price Not Available',
    adDetails: 'Ad Details',
    addNewAds: 'Create New Ad',
    AdInfo: 'Ad Information',
    AD_TYPE__IS_REQUIRED_FIELD: 'Ad Type Is Required Field',
    PUBLISH_NAME_IS_REQUIRED_FIELD: 'Publish Name Is Required Field',
    PACKAGE_IS_REQUIRED_FIELD: 'Package Is Required Field',
    adImagesAttachments: 'Ad Images',
    savingAdAttachmentNote: 'Saving Ad Attachment After Craete Ad',
    adVideoAttachments: 'Ad Video',
    adDocAttachments: 'Ad Document',
    SUCCESSFULLY_UPDATED_AD: 'Successfully Updated Ad',
    SUCCESSFULLY_UPLOADED_AD_IMAGES: 'Successfully Upload Ad Images',
    SUCCESSFULLY_UPLOADED_AD_VIDEO: 'Successfully Upload Ad Video',
    SUCCESSFULLY_UPLOADED_AD_DOC: 'Successfully Upload Ad Document',
    SUCCESSFULLY_REMOVE_AD_IMAGE: 'Successfully Remove Ad Image',
    updateAd: 'Update Ad',
    baseValue: 'Base Value',
    fourthDecreasedPercentage: 'Fourth Decreased Percentage',
    thirdDecreasedPercentageToolTip: 'Third Percent',
    fourthDecreasedPercentageToolTip: 'Fourth Percent',
    FIRST_DESC_IS_REQUIRED_FIELD: 'First Decreased Is Required Field',
    SECOND_DESC_IS_REQUIRED_FIELD: 'Second Decreased Is Required Field',
    THIRD_DESC_IS_REQUIRED_FIELD: 'Third Decreased Is Required Field',
    FOURTH_DESC_IS_REQUIRED_FIELD: 'Fourth Decreased Is Required Field',
    FIRST_BASE_VAL_IS_REQUIRED_FIELD: 'First Base Value Is Required Field',
    SECOND_BASE_VAL_IS_REQUIRED_FIELD: 'Second Base Value Is Required Field',
    THIRD_BASE_VAL_IS_REQUIRED_FIELD: 'Third Base Value Is Required Field',
    FOURTH_BASE_VAL_IS_REQUIRED_FIELD: 'Fourth Base Value Is Required Field',
    firstBaseValue: 'First Base Value',
    secondBaseValue: 'Second Base Value',
    thirdBaseValue: 'Third Base Value',
    fourthBaseValue: 'Fourth Base Value',
    selectePropertyCategory: 'Select Property Category',
    sizeQualityConfig: 'Size Quality Config',
    LeadID: 'Lead Id *From BBYN',
    isSent: 'Sent To BBYN',
    FinanceFundsRequest: 'Finance Funds Requests',
    financesFundsRequest: 'Finance Funds Requests',
    financeFundsRequestOverview: 'finance Funds Request Overview',
    siteUrl: 'Alhisba Site URL',
    resStatus: 'response Status *From BBYN',
    resStatusText: 'response Status Text *From BBYN',
    leadCode: 'lead Code *From BBYN',
    adsCount: 'AD Count',
    memberType: 'Member Type',
    officeName: 'Office Name',
    mostpublished: 'Most published',
    mostAdArea: 'Area',
    mostAdType: 'Ad Type',
    mostAdsPropertyCategory: 'Property Category',
    mostAdsPropertyType: 'Property Type',
    UnSelectAreas: 'Excluded Area',
    registeredTradesTopAreasReports: 'Trades Top Areas Reports',
    registeredTradesReports: 'Registered Trades Reports',
    AdsPerQuarterReports: 'Ads Per Quarter Reports',
    registeredTradesTopsReports: 'Registered Trades Tops Reports',
    NumOfLowestRegisteredTrades: 'Num Of Lowest Trades',
    NumOfTopRegisteredTrades: 'Num Of Top Trades',
    registeredTradesPerQuarterReports: 'Registered Trades Per Quarter Reports',
    RegisteredTradesTotalsReports: 'Registered Trades Gov Totals Reports',
    SUCCESSFULLY_GET_REPORTS_DATA: 'SUCCESSFULLY GET REPORTS DATA',
    pullRegisteredTrades: 'Pull Registered Trades',
    importRegisteredTrades: 'Import Registered Trades',
    SUCCESSFULLY_UPLOAD_REGISTERED_TRADE:
      'Successfully Upload Registered Trades',
    rejectSelected: 'Reject Selected',
    addNewRejection: "Add New Rejection",
    createNewRejection: "Add New Rejection",
    rejectionList: "Rejection List",
    rejectionEnLang: "English Rejection response..",
    rejectionArLang: "Arabic Rejection response..",
    SUCCESSFULLY_CREATED_REJECTION: "Successfully created rejection response",
    SUCCESSFULLY_UPDATED_REJECTION: "Successfully updated rejection response",
    selectRejectReason: "Select reject reason",
    rejectionsCreating: "Rejection Creating",
    rejectionsEditing: "Rejections Editing",
    linkEnLang: "Link text in English",
    linkArLang: "Link text in Arabic",
    linksCreating: "Links Creating",
    linksEditing: "Links Editing",
    linksList: "Links List",
    linkList: "Links List",
    createNewLink: "Create new Link",
    addNewLink: "Add new Link"
  },
  ar: {
    linkEnLang: "محتوى الرابط بالانجليزي",
    linkArLang: "محتوى الرابط بالعربي",
    linkCreating: "اضافة الروابط",
    linkEditing: "تعديل الرابط",
    linkList: "لائحة الروابط",
    linksList: "لائحة الروابط",
    createNewLink: "اضافة رابط",
    addNewLink: "اضافة رابط جديد",
    addNewRejection: "اضف رد رفضي",
    createNewRejection: "اضف رد رفض جديد",
    rejectionList: "معلومات الرفض",
    rejectionEnLang: "رد الرفض بالانجليزي...",
    rejectionArLang: "رد الرفض بالعربي...",
    rejectionsCreating: "اضافة رفض",
    rejectionsEditing: "تعديل رفض",
    SUCCESSFULLY_CREATED_REJECTION: "تم اضافة رد الرفض بنجاح",
    SUCCESSFULLY_UPDATED_REJECTION: "تم تحديث رد الرفض بنجاح",
    selectRejectReasons: "اختار سبب الرفض",
    signInTitle: 'تسجيل الدخول للحسبة ادمن',
    signInAppraiser: 'تسجيل دخول المثمنين',
    signInBanks: 'تسجيل دخول البنوك',
    email: 'البريد الإلكتروني',
    emailAddress: 'عنوان البريد الإلكتروني',
    password: 'كلمة المرور',
    continue: 'إستمرار',
    alternativeLocale: 'الحسبة ادمن متوفر ايضا باللغة: {0}',
    alternativeLocaleVal: 'English',
    pleaseWait: 'الرجاء الإنتظار...',
    tryAgainExcl: 'حاول مرة اخرى!',
    searchAdmins: 'بحث الادمن',
    INVALID_EMAIL_PASSWORD: 'إسم المستخدم او كلمة المرور غير صحيحة',
    INVALID_LONGITUDE: 'قيمة خط الطول غير صحيحة',
    INVALID_LATITUDE: 'قيمة خط العرض غير صحيحة',
    EMAIL_NOT_VALID: 'البريد الإلكتروني غير صحيح',
    EMAIL_IS_REQUIRED_FIELD: 'حقل البريد الإلكتروني مطلوب',
    PASSWORD_IS_REQUIRED_FIELD: 'حقل كلمة المرور مطلوب',
    VERIFY_PASSWORD_IS_REQUIRED_FIELD: 'حقل تأكيد كلمة المرور مطلوب',
    FULL_NAME_IS_REQUIRED_FIELD: 'حقل الإسم الكامل مطلوب',
    APPRAISER_NAME_IS_REQUIRED_FIELD: 'حقل اسم المقيم مطلوب',
    BANK_DETAILS_IS_REQUIRED_FIELD: 'حقل تفاصيل الحساب البنكي مطلوب',
    PHONE_IS_REQUIRED_FIELD: 'حقل الهاتف مطلوب',
    STARTING_PRICE_IS_REQUIRED_FIELD: 'حقل السعر المبدئي مطلوب',
    STARTING_PRICE_PER_SM_IS_REQUIRED_FIELD:
      'حقل السعر المبدئي لكل متر مربع مطلوب',
    PHONE_NUMBER_IS_REQUIRED_FIELD: 'حقل رقم الهاتف مطلوب',
    LOGO_IS_REQUIRED_FIELD: 'حقل الشعار مطلوب',
    PERSON_NAME_IS_REQUIRED_FIELD: 'حقل اسم جهة الاتصال مطلوب',
    PERSON_PHONE_IS_REQUIRED_FIELD: 'حقل هاتف جهة الاتصال مطلوب',
    EN_NAME_IS_REQUIRED_FIELD: 'حقل الإسم بالانجليزية مطلوب',
    EN_SIZE_IS_REQUIRED_FIELD: 'حقل المساحة بالانجليزية مطلوب',
    AR_SIZE_IS_REQUIRED_FIELD: 'حقل المساحة بالعربية مطلوب',
    FACTOR_OPTION_IVE_IS_REQUIRED_FIELD: 'حقل قيمة التقييم الفوري مطلوب',
    EN_VENUE_IS_REQUIRED_FIELD: 'حقل المكان بالانجليزية مطلوب',
    AR_NAME_IS_REQUIRED_FIELD: 'حقل الإسم بالعربية مطلوب',
    SCORE_IS_REQUIRED_FIELD: 'حقل محموع النقاط بالعربية مطلوب',
    EN_VALUE_IS_REQUIRED_FIELD: 'حقل القيمة بالانجليزية مطلوب',
    AR_VALUE_IS_REQUIRED_FIELD: 'حقل القيمة بالعربية مطلوب',
    AR_QUESTION_IS_REQUIRED_FIELD: 'حقل السؤال بالعربية مطلوب',
    IMAGE_URL_IS_REQUIRED_FIELD: 'حقل رابط الصورة مطلوب',
    URL_IS_REQUIRED_FIELD: 'حقل الرابط مطلوب',
    AR_ANSWER_IS_REQUIRED_FIELD: 'حقل الاجابة بالعربية مطلوب',
    AR_VENUE_IS_REQUIRED_FIELD: 'حقل المكان بالعربية مطلوب',
    AREA_VALUE_IS_REQUIRED_FIELD: 'حقل قيمة المنطقة مطلوب',
    PRICE_VALUE_IS_REQUIRED_FIELD: 'حقل السعر مطلوب',
    EN_QUESTION_IS_REQUIRED_FIELD: 'حقل السؤال بالانجليزية مطلوب',
    EN_ANSWER_IS_REQUIRED_FIELD: 'حقل الاجابة بالانجليزية مطلوب',
    EN_TITLE_IS_REQUIRED_FIELD: 'حقل العنوان باللغة الانجليزية مطلوب',
    AR_TITLE_IS_REQUIRED_FIELD: 'حقل العنوان باللغة العربية مطلوب',
    ACTIVE_STATUS_IS_REQUIRED_FIELD: 'حقل الحالة مطلوب',
    REJECTION_NOTE_IS_REQUIRED_FIELD: 'حقل مذكرة الرفض مطلوب',
    AUCTION_STATUS_IS_REQUIRED_FIELD: 'حقل حالة المزاد مطلوب',
    REQUEST_STATUS_IS_REQUIRED_FIELD: 'حقل حالة الطلب مطلوب',
    DESCRIPTION_IS_REQUIRED_FIELD: 'حقل الوصف مطلوب',
    ROLE_GROUP_IS_REQUIRED_FIELD: 'حقل مجموعة الدور مطلوب',
    ROLE_IS_REQUIRED_FIELD: 'حقل الدور مطلوب',
    ROLE_NAME_IS_REQUIRED_FIELD: 'حقل اسم الدور مطلوب',
    NON_CLIENT_PERCENTAGE_IS_REQUIRED_FIELD:
      'Non client Percentage is a required field',
    BASE_PRICE_IS_REQUIRED_FIELD: 'Base price is a required field',
    ELECTRICITY_IS_REQUIRED_FIELD: 'electricity is a required field',
    PERCENT_IS_REQUIRED_FIELD: 'حقل النسبة المئوية مطلوب',
    FROM_RANGE_IS_REQUIRED_FIELD: 'حقل من مطلوب',
    TO_RANGE_IS_REQUIRED_FIELD: 'حقل الى مطلوب',
    ADS_DURATION_IS_REQUIRED_FIELD: 'Ads duration is a required field ',
    BANNER_TYPE_IS_REQUIRED_FIELD: 'Banner type is a required field',
    SCREEN_BANNER_IS_REQUIRED_FIELD: 'Screen banner is a required field',
    ACTION_BANNER_IS_REQUIRED_FIELD: 'Action banner is a required field',
    EXTERNAL_LINK_IS_REQUIRED_FIELD: 'External link is a required field',
    ENTITY_ID_IS_REQUIRED_FIELD: 'Entity id is a required field',
    MERCHANT_IS_REQUIRED_FIELD: 'Merchant is a required field',
    WEIGHT_IS_REQUIRED_FIELD: 'Weight is a required field',
    GROUP_ID_IS_REQUIRED_FIELD: 'حقل رمز المجموعة مطلوب',
    SORT_IS_REQUIRED_FIELD: 'Sort is a required field',
    PASSWORD_AT_LEAST_ONE_NUMBER:
      'كلمة المرور يجب ان تحتوي على رقم واحد على الاقل',
    PASSWORD_AT_LEAST_ONE_LOWER:
      'كلمة المرور يجب ان تحتوي على حرف صغير واحد على الاقل',
    PASSWORD_AT_LEAST_ONE_UPPER:
      'كلمة المرور يجب ان تحتوي على حرف كبير واحد على الاقل',
    PASSWORD_AT_LEAST_ONE_SPECIAL:
      'كلمة المرور يجب ان تحتوي على رمز خاص واحد على الاقل',
    PASSWORD_MIN_4: 'كلمة المرور يجب ان تحتوي على ٤ احرف على الاقل',
    PASSWORD_MAX_20: 'كلمة المرور يجب ان تحتوي على ٢٠ حرف على الاكثر',
    PASSWORDS_MUST_MATCH: 'كلمة المرور غير متطابقة',
    SUCCESSFULLY_CREATED_ADMIN: 'تم إنشاء ادمن جديد بنجاح!',
    SUCCESSFULLY_CREATED_LOCATION: 'تم إنشاء موقع جديد بنجاح!',
    SUCCESSFULLY_CREATED_APPRAISER: 'تم إنشاء مقيم جديد بنجاح!',
    SUCCESSFULLY_CREATED_AUCTION: 'تم إنشاء مزاد جديد بنجاح!',
    SUCCESSFULLY_CREATED_AD_PACKAGE: 'Successfully created a new ad package!',
    SUCCESSFULLY_CREATED_ADS_SERVICE: 'تم إنشاء إعلان جديد بنجاح',
    SUCCESSFULLY_CREATED_IRE_AREA: 'Successfully created a new Ire area!',
    SUCCESSFULLY_CREATED_IRE_VALUE: 'Successfully created a new Ire Value',
    SUCCESSFULLY_CREATED_GROUP_QUESTION: 'تم إنشاء سؤال مجموعة جديد بنجاح!',
    SUCCESSFULLY_CREATED_GROUP_OPTIONS: 'تم إنشاء خيارات مجموعة جديدة بنجاح!',
    SUCCESSFULLY_UPDATED_QUALITY_GROUP: 'تم تحديث مجموعة الجودة بنجاح!',
    SUCCESSFULLY_UPDATED_APPRAISER: 'تم تحديث المقيم بنجاح!',
    SUCCESSFULLY_UPDATED_AUCTION: 'تم تحديث المزاد بنجاح!',
    SUCCESSFULLY_UPDATED_GROUP_QUESTION: 'تم تحديث سؤال مجموعة بنجاح!',
    SUCCESSFULLY_UPDATED_ADS_PACKAGE: 'Successfully updated ad package',
    SUCCESSFULLY_ADDED_AD_SERVICE: 'Successfully Added a new ad service',
    SUCCESSFULLY_ADDED_AD_PACKAGE_SERVICES:
      'Successfully added ad package services!',
    SUCCESSFULLY_ADDED_APPRAISER_BANKS: 'تم اضافة البنوك للمقيم بنجاح!',
    SUCCESSFULLY_UPLOADED_AUCTION_VIDEO: 'تم رفع فيديو المزاد بنجاح!',
    SUCCESSFULLY_UPLOAD_BANK_RATES: 'Successfully upload bank rates',
    SUCCESSFULLY_UPDATED_APPRAISER_BANKS: 'تم تعديل البنوك للمقيم بنجاح!',
    SUCCESSFULLY_ADDED_APPRAISER_PRICES: 'تم اضافة اسعار المقيم بنجاح!',
    SUCCESSFULLY_CREATED_BANK: 'تم إنشاء بنك جديد بنجاح!',
    SUCCESSFULLY_CREATED_ANSWER: 'تم إنشاء اجابة جديدة بنجاح!',
    SUCCESSFULLY_CREATED_IVE_VALUE: 'تم إنشاء قيمة تقييم فوري بنجاح!',
    SUCCESSFULLY_CREATED_STREET_OPTION: 'تم إنشاء خيار شارع جديد بنجاح!',
    SUCCESSFULLY_CREATED_LOCATION_SPECS_OPTION:
      'تم إنشاء خيار مواصفات موقع جديد بنجاح',
    SUCCESSFULLY_CREATED_LAND_SIZE_OPTION: 'تم انشاء خيار مساحة جديد بنجاح!',
    SUCCESSFULLY_CREATED_PLOT_SPECS_OPTION:
      'تم إنشاء خيار مواصفات قطعة ارض جديد بنجاح!',
    SUCCESSFULLY_CREATED_DIRECTION_OPTION: 'تم إنشاء خيار اتجاه بنجاح!',
    SUCCESSFULLY_CREATED_SABAH_CITY_SPECS_OPTION:
      'تم إنشاء خيار مواصفات مدينة صباح بنجاح!',
    SUCCESSFULLY_CREATED_FACING_OPTION: 'تم إنشاء خيار عقار مقابل بنجاح!',
    SUCCESSFULLY_CREATED_ORGANIZER: 'تم إنشاء منظم جديد بنجاح!',
    SUCCESSFULLY_CREATED_BANNER: 'Successfully created a new banner!',
    SUCCESSFULLY_CREATED_ROLE: 'تم إنشاء دور جديد بنجاح!',
    SUCCESSFULLY_CREATED_BANK_RATE: 'تم إنشاء تقييم بنك جديد بنجاح!',
    SUCCESSFULLY_CREATED_AD_TYPE: 'تم إنشاء نوع اعلان جديد بنجاح!',
    SUCCESSFULLY_CREATED_PROPERTY: 'تم إنشاء عقار جديد بنجاح!',
    SUCCESSFULLY_CREATED_AREA: 'تم إنشاء منطقة جديدة بنجاح!',
    SUCCESSFULLY_PULLED_DATA: 'تم سحب البيانات بنجاح!',
    SUCCESSFULLY_CREATED_NEWS: 'تم إنشاء خير جديد بنجاح!',
    SUCCESSFULLY_CREATED_PROPERTY_TYPE: 'تم إنشاء نوع عقار جديد بنجاح!',
    SUCCESSFULLY_CREATED_LOCATION_OPTION: 'تم إنشاء خيار موقع جديد بنجاح',
    SUCCESSFULLY_CREATED_CURB_OPTION: 'تم إنشاء خيار ارتداد جديد بنجاح!',
    SUCCESSFULLY_CREATED_BUILDING_AGE_OPTION:
      'تم إنشاء خيار عمر بناء جديد بنجاح!',
    SUCCESSFULLY_CREATED_FLOOR_OPTION: 'تم إنشاء خيار طابق جديد بنجاح!',
    SUCCESSFULLY_CREATED_FINISHING_QUALITY_OPTION:
      'تم إنشاء خيار جودة التشطيب بنجاح!',
    SUCCESSFULLY_CREATED_IRE_TYPE: 'Successfully created a new Ire Type',
    SUCCESSFULLY_CREATED_IRE_SIZE: 'Successfully created a new Ire Size',
    SUCCESSFULLY_CREATED_IRE_FACTOR_OPTIONS:
      'Successfully created a new Ire Options',
    SUCCESSFULLY_CREATED_IRE_FACTOR_QUALITY:
      'Successfully created a new Ire Quality',
    SUCCESSFULLY_CREATED_IRE_FACTOR_BATHROOMS:
      'Successfully created a new Ire Bathrooms',
    SUCCESSFULLY_CREATED_IRE_FACTOR_GENERAL_SPECS:
      'Successfully created a new Ire General specs',
    SUCCESSFULLY_CREATED_IRE_FACTOR_LOACATION_SPECS:
      'Successfully created a new Ire Location specs',
    SUCCESSFULLY_CREATED_IRE_FACTOR_PROPERTY_SERVICE:
      'Successfully created a new Ire Property Service',
    SUCCESSFULLY_CREATED_QUALITY_RANGE_OPTION: 'تم إنشاء خيار نطاق جودة بنجاح!',
    SUCCESSFULLY_CREATED_QUALITY_GROUP: 'تم إنشاء مجموعة جودة جديدة بنجاح!',
    SUCCESSFULLY_UPDATED_QUALITY_RANGE_OPTION: 'تم تحديث خيار نطاق جودة بنجاح!',
    SUCCESSFULLY_UPDATED_FLOOR_OPTION: 'تم تحديث خيار الطابق بنجاح!',
    SUCCESSFULLY_UPDATED_SEA_FRONT_OPTION:
      'تم تحديث خيار الواجهة البحرية بنجاح!',
    SUCCESSFULLY_UPDATED_BACK_STREET_OPTION:
      'تم تحديث خيار الشارع الخلفي بنجاح!',
    SUCCESSFULLY_UPDATED_BUILDING_AGE_OPTION: 'تم تحديث خيار عمر بناء بنجاح!',
    SUCCESSFULLY_UPDATED_FINISHING_QUALITY_OPTION:
      'تم تحديث خيار جودة التشطيب بنجاح!!',
    SUCCESSFULLY_UPDATED_LOCATION_OPTION: 'تم تحديث خيار موقع جديد بنجاح',
    SUCCESSFULLY_UPDATED_ADS_PACKAGE_SERVICES:
      'Successfully updated ads package services',
    SUCCESSFULLY_UPDATED_FACING_OPTION: 'تم تحديث خيار عقار مقابل بنجاح!',
    SUCCESSFULLY_UPDATED_SABAH_CITY_SPECS_OPTION:
      'تم تحديث خيار مواصفات مدينة صباح بنجاح!',
    SUCCESSFULLY_UPDATED_STREET_OPTION: 'تم تحديث خيار شارع بنجاح!',
    SUCCESSFULLY_UPDATED_ROLE: 'تم تحديث الدور بنجاح!',
    SUCCESSFULLY_UPDATED_LOCATION_SPECS_OPTION: 'تم تحديث خيار الموقع بنجاح',
    SUCCESSFULLY_UPDATED_AS_DEFAULT: 'Successfully set as default!',
    SUCCESSFULLY_UPDATED_LAND_SIZE_OPTION: 'تم تحديث خيار مساحة الارض بنجاح!',
    SUCCESSFULLY_UPDATED_DIRECTION_OPTION: 'تم تحديث خيار الاتجاه بنجاح!',
    SUCCESSFULLY_UPDATED_ADMIN: 'تم تحديث الادمن بنجاح!',
    SUCCESSFULLY_UPDATED_BANK: 'تم تحديث البنك بنجاح!',
    SUCCESSFULLY_UPDATED_ANSWER: 'تم تحديث الاجابة بنجاح!',
    SUCCESSFULLY_UPDATED_ORGANIZER: 'تم تحديث المنظم بنجاح!',
    SUCCESSFULLY_UPDATED_LOCATION: 'تم تحديث الموقع بنجاح!',
    SUCCESSFULLY_UPDATED_AD_TYPE: 'تم تحديث نوع الاعلان بنجاح!',
    SUCCESSFULLY_UPDATED_PROPERTY: 'تم تحديث العقار بنجاح!',
    SUCCESSFULLY_UPDATED_FAQ: 'تم تحديث السؤال الشائع بنجاح!',
    SUCCESSFULLY_UPDATED_AREA: 'تم تحديث المنطقة بنجاح!',
    SUCCESSFULLY_UPDATED_PROPERTY_TYPE: 'تم تحديث نوع العقار بنجاح!',
    SUCCESSFULLY_UPDATED_ABOUT_US: 'تم تحديث نبذة عنا بنجاح!',
    SUCCESSFULLY_UPDATED_TERMS_AND_CONDITIONS: 'تم تحديث الشروط والحكام بنجاح!',
    SUCCESSFULLY_UPDATED_APPRAISAL_REQUEST: 'تم تحديث طلب التقييم بنجاح!',
    SUCCESSFULLY_UPDATED_PLOT_SPECS_OPTION:
      'تم تحديث مواصفات قطعة الارض بنجاح!',
    SUCCESSFULLY_UPDATED_GROUPS_WEIGHT: 'تم تحديث وزن المجوعات بنجاح!',
    SUCCESSFULLY_UPDATED_QUESTIONS_WEIGHT: 'تم تحديث وزن الاسئلة بنجاح',
    SUCCESSFULLY_UPDATED_CURB_OPTION: 'تم تحديث خيار ارتداد بنجاح!',
    SUCCESSFULLY_UPDATED_IRE_TYPE: 'Successfully updated ire type!',
    SUCCESSFULLY_UPDATED_IRE_SIZE: 'Successfully updated ire size!',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_BATHROOM:
      'Successfully updated ire Bathroom',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_OPTION: 'Successfully updated ire Option',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_QUALITY: 'Successfully updated ire Quality',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_GENERAL_SPECS:
      'Successfully updated ire General specs',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_LOCATION_SPECS:
      'Successfully updated ire Location_SPECS',
    SUCCESSFULLY_UPDATED_IRE_FACTOR_PROPERTY_SERVICES:
      'Successfully updated ire Property Services',
    SUCCESSFULLY_UPDATED_IRE_VALUE: 'Successfully updated ire value!',
    SUCCESSFULLY_UPDATED_BANK_RATE: 'تم تحديث تقييم البنك بنجاح!',
    SUCCESSFULLY_UPDATED_BANK_RATE_SETTINGS:
      'تم تحديث اعدادات تقييم البنك بنجاح!',
    SESSION_EXPIRED: 'انقضت الجلسة',
    SESSION_EXPIRED_TEXT: 'سيتم تحويلك الى شاشة تسجيل الدخول',
    createdDate: 'تاريخ الانشاء',
    createdBy: 'انشأ من قبل',
    confirm: 'Confirm',
    confirmDelete: 'Confirm delete',
    confirmInActive: 'Are you sure you want to inactivate it?',
    confirmActive: 'Are you sure you want to activate it',
    confirmApproveAll: 'Are you sure you want to approve all items?',
    description: 'الوصف',
    read: 'مشاهدة',
    create: 'انشاء',
    update: 'تحديث',
    delete: 'ازالة',
    shortDescription: 'وصف قصير',
    permission: 'الإذن',
    confirmUserBlock: 'نعم, قم بحظر المستخدم!',
    applicantInfo: 'معلومات مقدم الطلب',
    areYouSureQ: 'هل انت متأكد؟',
    youCantRevert: 'لا يمكن التراجع عن الحذف!',
    selectPermissions: 'اختر الاذونات',
    permissionsCount: 'لغاية {count} مصادر',
    updateAdmin: 'تحديث الادمن',
    updateBank: 'تحديث بنك',
    updateAnswer: 'تحديث اجابة',
    updateStreetOption: 'تحديث خيار شارع',
    updateBuildingAgeOption: 'تحديث خيار عمر البناء',
    updateFinishingQualityOption: 'تحديث خيار جودة التشطيب',
    updateFloorOption: 'تحديث خيار الطابق',
    updateSeaFrontLengthOption: 'تحديث خيار طول الواجهة البحرية',
    updateBackStreetLengthOption: 'تحديث خيار طول الشارع الخلفي',
    updateLocationOption: 'تحديث خيار الموقع',
    updateCurbOption: 'تحديث خيار الارتداد',
    updateOrganizer: 'تحديث منظم',
    updateLocation: 'تحديث موقع',
    updateAdType: 'تحديث نوع الاعلان',
    updateProperty: 'تحديث العقار',
    updateFaq: 'تحديث سؤال شائع',
    updateContact: 'تحديث وسائل التواصل',
    updateArea: 'تحديث المنطقة',
    updateIveAreaValue: 'تحديث المنطقة',
    updatePropertyType: 'تحديث نوع العقار',
    updateAboutUs: 'تحديث نبذة عنا',
    updateTermsAndConditions: 'تحديث الشروط والاحكام',
    updateLandSizeOption: 'تحديث خيار مساحة الارض',
    updateLocationSpecsOption: 'تحديث خيار الموقع',
    updateDirectionOption: 'تحديث خيار اتجاه',
    updateSabahCitySpecsOption: 'تحديث خيار مواصفاات مدينة صباح',
    updateFacingOption: 'تحديث خيار عقار مقابل',
    updateQualityRangeOption: 'تحديث خيار نطاق الجودة',
    updateQualityGroup: 'تحديث مجموعة الجودة',
    updatePlotSpecsOption: 'تحديث مواصفات قطعة ارض',
    UpdateloansFundsRequest: 'Update Loans Fund Request',
    updateGroupQuestion: 'تحديث سؤال المجموعة',
    updateGroup: 'تحديث المجموعة',
    updateIreType: 'Update Ire Type',
    updateIreFactorBathroom: 'Update Ire Factor Bathroom',
    updateIreFactorsOptions: 'Update Ire Factor Options',
    updateIreFactorsQualitys: 'Update Ire Factor Quality',
    updateIreLocationSpecs: 'Update Ire Location specs',
    updateIreFactorGeneralspecs: 'Update Ire Factor General specs',
    updateIreFactorsPropertyService: 'Update Ire Factor Property Service',
    updateBankRate: 'تحديث اقييم البنك',
    settingBankRate: 'إعدادات',
    updateQuestionsWeight: 'Update Questions Weight',
    updateGroupsWeight: 'Update Groups Weight',
    updatenews: 'تحديث الاخبار',
    bankRatesOverview: 'نظرة عامة على تقييم البنوك',
    changeAppraisalRequestStatus: 'تغيير حالة طلب التقييم',
    updateRole: 'تحديث الدور',
    addNewAdmin: 'اضافة ادمن جديد',
    addNewAppraiser: 'اضافة مقيم جديد',
    addNewBank: 'اضافة بنك جديد',
    addNewAnswer: 'اضافة اجابة جديدة',
    addNewLandSizeOption: 'اضافة مساحة ارض جديدة',
    addNewDirectionOption: 'اضافة خيار اتجاه جديد',
    addNewSabahCitySpecsOption: 'اضافة خيار مواصفات مدينة صباح جديد',
    addNewLocationSpecsOption: 'اضافة خيار مواصفات موقع جديد',
    addNewPlotSpecsOption: 'اضافة مواصفات قطعة ارض جديدة',
    addNewStreetOption: 'اضافة خيار شارع جديد',
    addNewLocationOption: 'اضافة خيار موقع جديد',
    addNewBuildingAgeOption: 'اضافة خيار عمر بناء جديد',
    addNewFinishingQualityOption: 'اضافة خيار جودة تشطيب جديد',
    addNewFloorsOption: 'اضافة خيار طوابق جديد',
    addNewCurbOption: 'اضافة خيار منعطف جديد',
    addNewOrganizer: 'اضافة منظم جديد',
    addNewLocation: 'اضافة موقع جديد',
    addNewRole: 'اضافة دور جديد',
    addNewQualityRangeOption: 'اضافة خيار نطاق جودة جديد',
    addNewAdType: 'اضافة نوع اعلان جديد',
    addNewProperty: 'اضافة عقار جديد',
    addNewIveValue: 'اضافة قيمة تقييم جديدة',
    updateIreValue: 'Update  Ire Value',
    UpdateExistingValues: 'Update Existing Values',
    addNewFaq: 'اضافة سؤال شائع جديد',
    addNewArea: 'اضافة منطقة جديدة',
    addNewIVEAreaValue: 'اضافة منطقة جديدة',
    addNewPropertyTypes: 'اضافة نوع عقار جديد',
    addNewPropertyCategory: 'اضافة فئة عقار جديد',
    addNewFacingOption: 'اضافة عقار مقابل جديد',
    addNewQualityGroup: 'اضافة مجموعة جودة جديدة',
    addNewGroupQuestion: 'اضافة سؤال مجموعة جديد',
    addNewIreArea: 'Add New Ire Area',
    addNewIreType: 'Add New Ire Type',
    addNewIreSize: 'Add New Ire Size',
    addNewIreFactorBathrooms: 'Add New Ire Factor Bathroom',
    addNewIreFactorsOptions: 'Add New Ire Factor Option',
    addNewIreFactorsQualitys: 'Add New Ire Factor Quality',
    addNewIreFactorsLocationSpecs: 'Add New Ire Factor Location Specs',
    addNewIreFactorGeneralspecs: 'Add New Ire Factor General specs',
    addNewIreFactorsPropertyService: 'Add New Ire Factor Propert Service',
    addNewBanner: 'Add New Banner',
    addNewBankRate: 'إنشاء تقييم بنك جديد',
    adminUsersListing: 'قائمة الاعضاء الادمن',
    auctionsListing: 'قائمة المزادات',
    appraisersUsersListing: 'قائمة الاعضاء المُقيمين',
    appraisersListing: 'قائمة المقيمين',
    usersListing: 'قائمة المستخدمين',
    banksListing: 'قائمة البنوك',
    answersListing: 'قائمة الإجابات',
    bannerList: 'Banner Listing',
    ireAreasListing: 'Ire Areas Listing',
    ireTypesListing: 'Ire Types Listing',
    ireSizesListing: 'Ire Size Listing',
    IreFactorsBathroomsListing: 'Ire Factors Bathroom Listing',
    IreFactorsOptionsListing: 'Ire Factor Options Listing',
    IreFactorsQualitysListing: 'Ire Factor Qualitys Listing',
    IreFactorsGeneralspecsListing: 'Ire Factors General specs Listing',
    ireFactorsLocationSpecsListing: 'Ire Factors Location Specs Listing',
    IreFactorsPropertySeriveListing: 'Ire Factors Property Service Listing',
    streetFactorOptions: 'خيارات عامل الشارع',
    facingFactorOptions: 'خيارات عامل العقار مقابل',
    facingOptionCreating: 'انشاء خيار عقار مقابل',
    facingOptionUpdating: 'تحديث خيار عقار مقابل',
    sabahCityFactorOptions: 'خيارات عامل مدينة صباح',
    sabahCityOptionCreating: 'إنشاء خيار مدينة صباح',
    sabahCityOptionUpdating: 'تحديث خيار مدينة صباح',
    locationFactorOptions: 'خيارات عامل الموقع',
    locationOptionUpdating: 'تحديث خيار الموقع',
    locationOptionCreating: 'إنشاء خيار الموقع',
    curbFactorOptions: 'خيارات عامل الارتداد',
    curbOptionUpdating: 'تحديث خيار الارتداد',
    curbOptionCreating: 'إنشاء خيار الارتداد',
    buildingAgeFactorOptions: 'خيارات عامل عمر البناء',
    buildingAgeOptionUpdating: 'تحديث خيار عمر بناء',
    buildingAgeOptionCreating: 'إنشاء خيار عمر بناء',
    finishingQualityFactorOptions: 'خيارات عامل جودة التشطيب',
    finishingQualityOptionUpdating: 'تحديث خيار جودة التشطيب',
    finishingQualityOptionCreating: 'إنشاء خيار جودة التشطيب',
    floorFactorOptions: 'خيارات عامل الطابق',
    floorOptionsCreating: 'إنشاء خيارات الطابق',
    landingEstimator: 'Land Estimator',
    landingEstimatorClone: 'Land Estimator Clone',
    FactorsBathroomValues: 'Factors Bathroom Values',
    FactorsOptionValues: 'Factors Option Values',
    qualityGroupsLisitng: 'قائمة مجموعات الجودة',
    floorOptionsUpdating: 'تحديث خيارات الطابق',
    seaFrontLenghsFactorOptions: 'خيارات اطوال الواجهة البحرية',
    backStreetLenghsFactorOptions: 'خيارات اطوال الشارع الخلفي',
    seaFrontLengthOptionUpdating: 'تحديث خيار طول الواجهة البحرية',
    streetOptionsUpdating: 'تحديث خيار شارع',
    organizersListing: 'قائمة المنظمين',
    locationsListing: 'قائمة المواقع',
    contactusListing: 'قائمة التواصل  ',
    adTypesListing: 'قائمة الاعلانات',
    propertiesListing: 'قائمة العقارات',
    faqsListing: 'قائمة الاسئلة الشائعة',
    areasListing: 'قائمة المناطق',
    areasIveValuesListing: 'قائمة المناطق',
    areasIveValuesCreating: 'إنشاء منطقة جديدة',
    areasIveValuesUpdating: 'تحديث منطقة',
    finishingqualityEn: 'جودة التشطيب - الانجليزية',
    finishingqualityAr: 'جودة التشطيب - العربية',
    finishingQualityOptionNameArLang: 'خيار جودة التشطيب في اللغة العربية',
    finishingQualityOptionNameEnLang: 'خيار جودة التشطيب في اللغة الانجليزية',
    landSizeFactorOptions: 'خيارات عامل مساحة الارض',
    landSizeOptionsUpdating: 'تحديث خيار مساحة الارض',
    landSizeOptionsCreating: 'انشاء خيار مساحة ارض جديد',
    directionFactorOptions: 'خيارات عامل الاتجاه',
    directionOptionsCreating: 'انشاء خيار اتجاه جديد',
    directionOptionsUpdating: 'تحديث خيار الاتجاه',
    PlotSpecsFactorOptions: 'خيارات عامل قطعة الارض',
    plotSpecsOptionsCreating: 'انشاء موصافات قطعة ارض جديدة',
    rangesFinishingQuality: 'نطاقات جودة التشطيب',
    qualityRangeOptionCreating: 'إنشاء خيار نطاق جودة جديد',
    qualityRangeOptionUpdating: 'تحديث خيار نطاق جودة',
    qualitygroupOptionCreating: 'إنشاء خيار مجموعة جودة',
    GroupsWeightUpdating: 'تحديث وزن المجموعات',
    QuestionsWeightUpdating: 'تحديث وزن الاسئلة',
    aboutUs: 'نبذة عنا',
    termsAndConditions: 'الشروط والاحكام',
    propertyTypesListing: 'قائمة انواع العقارات',
    appraisalsRequestsListing: 'قائمة طلبات التقييمات',
    appraisalsRequestsUpdating: 'تحديث طلبات التقييمات',
    adminUsersCreating: 'انشاء ادمن جديد',
    appraisersUsersCreating: 'انشاء مقيم جديد',
    auctionsCreating: 'انشاء مزاد جديد',
    bannerCreating: 'Add Banner Creating',
    auctionsEditing: 'تعديل مزاد',
    appraisersUsersEditing: 'تعديل مقيم',
    banksCreating: 'انشاء بنك جديد',
    answersCreating: 'انشاء اجابة جديدة',
    streetOptionsCreating: 'انشاء خيار شارع جديد',
    organizersCreating: 'انشاء منظم جديد',
    locationsCreating: 'انشاء موقع جديد',
    adTypesCreating: 'انشاء نوع اعلان جديد',
    propertiesCreating: 'انشاء عقار جديد',
    faqsCreating: 'انشاء سؤال شائع جديد',
    faqsUpdating: 'تحديث سؤال شائع',
    propertyTypesCreating: 'انشاء نوع عقار جديد',
    propertyCategoryCreating: 'انشاء فئة عقار جديد',
    propertyTypesUpdating: 'تحديث نوع العقار',
    propertyCategoryUpdating: 'تحديث فئة العقار',
    propertyCategoriesListing: 'قائمة فئات العقارات',
    propertyCategoriesCreating: 'انشاء فئة عقار جديد',
    propertyCategoriesUpdating: 'تحديث فئة العقار',
    locationSpecsFactorOptions: 'خيارات عامل مواصفات الموقع',
    locationSpecsOptionsCreating: 'اضافة خيارات الموقع',
    ireTypesCreating: 'Ire Types Creating',
    ireFactorsbathroomsCreating: 'Ire Factor Bathrooms Creating',
    ireFactorsQualitysCreating: 'Ire Factor Quality Creating ',
    ireFactorsPropertyServiceCreating: 'Ire Factor Property Service Creating',
    locationSpecsOptionsUpdating: 'تعديل خيارات الموقع',
    aboutUsUpdating: 'تحديث نبذة عنا',
    termsAndConditionsUpdating: 'تحديث الشروط والاحكام',
    adminUsersUpdating: 'تحديث الادمن',
    banksUpdating: 'تحديث بنك',
    answersUpdating: 'تحديث اجابة',
    organizersUpdating: 'تحديث منظم',
    locationUpdating: 'تحديث موقع',
    adTypesUpdating: 'تحديث نوع الاعلان',
    areasCreating: 'انشاء منطقة جديدة',
    newsCreating: 'إنشاء خبر جديد',
    newsUpdating: 'تحديث الخبر',
    areasUpdating: 'تحديث منطقة',
    appraisalRequestUpdating: 'تحديث طلب التقييم',
    roleUpdating: 'تحديث الدور',
    rolesListing: 'قائمة الادوار',
    rolesCreating: 'انشاء دور جديد',
    bankRateUpdating: 'تحديث تقييم البنك',
    bankRatesListing: 'قائمة تقييمات البنك',
    bankRateCreating: 'انشاء تقييم بنك',
    bankRatesLogReportsListing: 'bank Rates Log Reports Listing',
    importBankRate: 'Import Bank Rate',
    logReports: 'Log Reports',
    rolesUpdating: 'تحديث الدور',
    propertiesUpdating: 'تحديث العقار',
    ireTypesUpdating: 'Ire Type Updating',
    ireSizesCreating: 'Ire Size Creating',
    ireFactorsOptionsCreating: 'Ire Factors Option Creating',
    ireFactorsGeneralspecsCreating: 'Ire Factors General specs Creating',
    ireFactorsLocationspecsCreating: 'Ire Factors Location specs Creating',
    ireFactorsBathroomCreating: 'Ire Factors Bathroom specs Creating',
    ireSizeUpdating: 'Ire Size Updating',
    ireFactorsbathroomsUpdating: 'Ire Factors Bathrooms Updating',
    ireFactorsPropertyServiceUpdating: 'Ire Factors Property Service Updating',
    ireFactorsOptionssUpdating: 'Ire Factors Options Updating',
    ireFactorGeneralspecsUpdating: 'Ire Factors General specs Updating',
    ireFActorsLocationspecsUpdating: 'Ire Factors Location specs Updating',
    name: 'الاسم',
    roleName: 'إسم الدور',
    roleGroup: 'مجموعة الدور',
    status: 'الحالة',
    actions: 'إجرائات',
    members: 'الاعضاء',
    auctions: 'المزادات',
    appraisals: 'التقييمات',
    ive: 'IVE',
    assets: 'الاصول',
    settings: 'الاعدادات',
    ads: 'الاعلانات',
    active: 'فعال',
    inactive: 'غير فعال',
    createNewAdmin: 'انشاء ادمن جديد',
    createNewAppraiser: 'انشاء مقيم جديد',
    createNewBank: 'انشاء بنك جديد',
    createNewAnswer: 'انشاء اجابة جديد',
    createNewStreetOption: 'انشاء خيار شارع جديد',
    createNewGroup: 'إنشاء مجموعة جديدة',
    createNewBuildingAgeOption: 'إنشاء خيار عمر بناء جديد',
    createNewFinishingQualityOption: 'إنشاء خيار جودة تشطيب جديد',
    createNewFloorOption: 'إنشاء خيار طابق جديد',
    createNewLandSizeOption: 'انشاء خيار مساحة ارض جديد',
    createNewDirectionOption: 'إنشاء خيار إتجاه جديد',
    createNewSabahCitySpecsOption: 'إنشاء خيار مواصفات مدينة صباح جديد',
    createNewFacingOption: 'إنشاء خيار عقار مقابل جديد',
    createNewPlotSpecsOption: 'انشاء مواصفات قطعة ارض جديدة',
    createNewLocationSpecsOption: 'انشاء خيار موقع جديد',
    createNewOrganizer: 'انشاء منظم جديد',
    createNewLocation: 'انشاء موقع جديد',
    creatNewAdType: 'انشاء نوع اعلان جديد ',
    createNewRole: 'انشاء دور جديد',
    createNewProperty: 'انشاء عقار جديد',
    createNewPropertyCategory: 'انشاء فئة عقار جديدة',
    createNewFaq: 'انشاء سؤال شائع جديد',
    createNewArea: 'انشاء منطقة جديدة',
    createNewIveValue: 'إنشاء منطقة جديدة',
    createNewPropertyType: 'انشاء نوع عقار جديد',
    createNewLocationOption: 'إنشاء خيار موقع جديد',
    createNewCurbOption: 'إنشاء خيار ارتداد جديد',
    createNewQualityRangeOption: 'إنشاء خيار نطاق جودة جديد',
    createNewIreArea: 'Create New Ire Area',
    createNewIreType: 'Create New Ire Type',
    createNewIreSize: 'Create New Ire Size',
    createNewIreFactorBathrooms: 'Create New Ire Factor Bathrooms',
    createNewIreFactorOptions: 'Create New Ire Factor Options',
    createNewIreFactorGeneralspecs: 'Create New Ire Factor General specs',
    createNewIreFactorsLocationspecs: 'Create New Ire Factor Location specs',
    createNewIreFactorsQualitys: 'Create New Ire Factor Quality',
    createNewIreFactorsPropertyService:
      'Create New Ire Factor Property Service',
    createNewBankRate: 'إنشاء تقييم بنك جديد',
    createNewNews: 'إنشاء اخبار جديدة',
    createNewIreStreet: 'انشاء شارع جديد',
    fullName: 'الإسم الكامل',
    fullAdminName: 'اسم الادمن الكامل',
    adminEmail: 'البريد الإلكتروني للادمن',
    newPassword: 'كلمة المرور الجديدة',
    verifyPassword: 'تأكيد كلمة المرور',
    discard: 'إلغاء',
    saveChanges: 'حفظ',
    ok: 'موافق',
    yes: 'نعم',
    no: 'لا',
    appraiserName: 'إسم المقيم',
    appraiserEmail: 'البريد الالكترومي لـ المقيم',
    areaName: 'إسم المنطقة',
    price: 'السعر',
    basePrice: 'Base Price',
    attachments: 'المرفقات',
    attachmentsCount: 'يوجد {count} ملف من المرفقات',
    images: 'الصور',
    civilId: 'الهوية المدنية',
    allowedFileTypes: 'Allowed file types: png, jpg, jpeg.',
    documents: 'الوثائق',
    other: 'اخرى',
    appraisalDetails: 'تفاصيل التقييم',
    changeStatus: 'تغيير الحالة',
    applicantName: 'إسم مقدم الطلب',
    applicantEmail: 'البريد الالكتروني لـ مقدم الطلب',
    applicantMobile: 'هاتف مقدم الطلب',
    appraiserMobile: 'هاتف المقيم',
    appraisalPropertyStatus: 'حالة العقار',
    appraisalPropertyTypes: 'نوع العقار',
    remarks: 'ملاحظات',
    additionalDetails: 'تفاصيل اضافية',
    address: 'الموقع',
    bankAppraisal: 'عقار بنكي',
    activeStatus: 'حالة النشاط',
    rejectionNote: 'Rejection Note',
    areaSize: 'المساحة',
    appraisalRequestOverview: 'عرض طلب التقييم',
    selectStatus: 'إختر حالة...',
    selectAuctionStatus: 'Select an Auction Status',
    selectAdsPackage: 'Select an Ads Package',
    SelectScreen: 'Select a Screen',
    selectDirection: 'Select a Direction',
    selectBannerType: 'select A banner Type',
    selectpropertyStatus: 'Selecte a Property Status',
    selectGroup: 'إختر مجموعة...',
    selectOrganizer: 'إختر منظم...',
    selectPropertyType: 'إختر نوع عقار...',
    selectRole: 'إختر دور...',
    selectBanks: 'إختر بنوك...',
    selectFactorOptions: 'إختر عامل...',
    selectAreas: 'إختر منطقة...',
    roleGroupTooltip: 'الاذونات المتاحة تختلف من مجموعة الى اخرى',
    clearAll: 'مسح الكل',
    basicInfo: 'معلومات اساسية',
    propertyInfo: 'معلومات الملكية',
    backToList: 'الرجوع الى القائمة',
    appraisalRequestStatus: 'حالة الطلب',
    appraisalStatus0: 'تم استلام الطلب',
    appraisalStatus1: 'قيد المراجعة',
    appraisalStatus2: 'مرفوض من قبل المقيم',
    appraisalStatus3: 'مقبول من مقبل المقيم',
    appraisalStatus4: 'تم تسليم التقرير',
    idColumn: 'S.NO',
    organizerNameAr: 'إسم المنظم - العربية',
    organizerInfo: 'معلومات المنظم',
    bankNameEn: 'إسم البنك - الإنجليزية',
    streetNameEn: 'إسم الشارع - الإنجليزية',
    bankNameAr: 'إسم البنك - العربية',
    streetNameAr: 'إسم الشارع - العربية',
    bankNameEnLang: 'إسم البنك في اللغة الإنجليزية',
    answerEnLang: 'الاجابة في اللغة الإنجليزية',
    streetNameEnLang: 'إسم الشارع في اللغة الإنجليزية',
    venueNameEnLang: 'إسم المكان في اللغة الإنجليزية',
    bankNameArLang: 'إسم البنك في اللغة العربية',
    answerArLang: 'الاجابة في اللغة العربية',
    baseSize: 'Basic Size',
    streetNameArLang: 'إسم الشارع في اللغة العربية',
    organizerNameArLang: 'إسم المنظم في اللغة العربية',
    organizerNameEnLang: 'إسم المنظم في اللغة الإنجليزية',
    venueNameArLang: 'إسم المكان في اللغة العربية',
    adTypeNameEn: 'إسم الاعلان - الإنجليزية',
    adTypeNameAr: 'إسم الاعلان - العربية',
    adTypeNameArLang: 'اسم نوع الاعلان في اللغة العربية',
    adTypeNameEnLang: 'اسم نوع الاعلان في اللغة الانجليزية',
    propertyNameEn: 'إسم العقار - الانجليزية',
    propertyNameAr: 'إسم العقار - العربية',
    propertyTypeNameEn: 'إسم نوع العقار - الانجليزية',
    propertyTypeNameAr: 'إسم نوع العقار - العربية',
    propertCategoryNameEn: 'إسم فئة العقار - الانجليزية',
    propertyCategoryNameAr: 'إسم فئة العقار - العربية',
    propertyNameEnLang: 'إسم العقار في اللغة الانجليزية',
    propertyNameArLang: 'إسم العقار في اللغة العربية',
    landSizeNameEnLang: 'مساحة الارض في اللغة الانجليزية',
    landSizeNameArLang: 'مساحة الارض في اللغة العربية',
    plotSpecsNameEnLang: 'إسم خيار مواصفات قطعة ارض في اللغة الانجليزية',
    plotSpecsNameArLang: 'إسم خيار مواصفات قطعة ارض في اللغة العربية',
    landSizeValue: ' قيمة مساحة الارض',
    plotSpecsNameEn: 'إسم مواصفات قطعة الارض - الانجليزية',
    plotSpecsNameAr: 'إسم مواصفات قطعة الارض - العربية',
    directionNameEn: 'إسم الاتجاه - الانجليزية',
    sabahSeaSpecsNameEn: 'إسم مواصفات مدينة صباح -الانجليزية',
    sabahSeaSpecsNameAr: 'إسم مواصفات مدينة صباح -العربية',
    sabahSeaSpecsNameEnLang: 'إسم مواصفات مدينة صباح في اللغة الانجليزية',
    sabahSeaSpecsNameArLang: 'إسم مواصفات مدينة صباح في اللغة العربية',
    directionCityNameEn: 'إسم الاتجاه - الانجليزية',
    directionNameAr: 'إسم الاتجاه - العربية',
    directionOptionNameArLang: ' اسم خيار الاتجاه في اللغة العربية',
    directionOptionNameEnLang: ' اسم خيار الاتجاه في اللغة الانجليزية',
    buildingAgeValueEn: 'قيمة عمر البناء - الانجليزية',
    buildingAgeValueAr: 'قيمة عمر البناء - العربية',
    buildingAgeOptionValueEnLang: 'قيمة خيار عمر البناء في اللغة الانجليزية',
    quality: 'الجودة',
    buildingAgeOptionValueArLang: 'قيمة خيار عمر البناء في اللغة العربية',
    floorNameEn: 'اسم الطابق - الانجليزية',
    floorNameAr: 'اسم الطابق - العربية',
    floorOptionNameEnLang: 'اسم خيار الطابق في اللغة الانجليزية',
    floorOptionNameArLang: 'اسم خيار الطابق في اللغة العربية',
    seaFrontNameEn: 'إسم الواجهة البحرية - الانجليزية',
    seaFrontNameAr: 'إسم الواجهة البحرية - العربية',
    seaFrontOptionNameArLang: 'إسم خيار الواجهة البحرية في اللغة العربية',
    seaFronOptionNameEnLang: 'إسم خيار الواجهة البحرية في اللغة الانجليزية',
    backStreetNameEn: 'إسم الشارع الخلفي -الانجليزية',
    backStreetNameAr: 'إسم الشارع الخلفي -العربية',
    backStreetOptionNameEnLang: 'إسم خيار الشارع الخلفي في اللغة الانجليزية',
    backStreetOptionNameArLang: 'إسم خيار الشارع الخلفي في اللغة العربية',
    qualityNameEn: 'اسم الجودة - الانجليزية',
    qualityNameAr: 'اسم الجودة - العربية',
    qualityRangeNameOptionArLang: 'اسم خيار نطاق الجودة في اللغة العربية',
    qualityRangeNameOptionEnLang: 'اسم خيار نطاق الجودة في اللغة الانجليزية',
    qualityGroupNameEnLang: 'اسم مجموعة الجودة في اللغة الانجليزية',
    qualityGroupNameArLang: 'اسم المجموعة الجودة في اللغة العربية',
    questionNameEnLang: 'اسم السؤال في اللغة العربية',
    questionNameArLang: 'اسم السؤال في اللغة الانجليزية',
    questionEnLang: 'السؤال في اللغة الانجليزية',
    questionArLang: 'السؤال في اللغة العربية',
    imageUrl: 'رابط الصورة',
    multiplicationFactors: 'Multiplication Factor',
    answerScore: 'نقاط الاجابة',
    locationSpecsNameEn: 'إسم الموقع - الانجليزية',
    locationSpecsNameAr: 'إسم الموقع - العربية',
    locationSpecsOptionNameEnLang:
      'إسم خيار مواصفات الموقع في اللغة الانجليزية',
    locationSpecsOptionNameArLang: 'إسم خيار مواصفات الموقع في اللغة العربية',
    facingNameEn: 'اسم العقار المقابل - الانجليزية',
    facingNameAr: 'اسم العقار المقابل - العربية',
    facingOptionNameEnLang: 'إسم العقار المقابل في اللغة الانجليزية',
    facingOptionNameArLang: 'إسم العقار المقابل في اللغة العربية',
    locationNameEn: 'إسم الموقع - الانجليزية',
    locationNameAr: 'إسم الموقع - العربية',
    locationOptionNameEnLang: 'إسم خيار الموقع في اللغة الانجليزية',
    locationOptionNameArLang: 'إسم خيار الموقع في اللغة العربية',
    curbNameEn: 'إسم الارتداد - الانجليزية',
    curbNameAr: 'إسم الارتداد - العربية',
    curbOptionNameEnLang: 'إسم خيار الارتداد في اللغة الانجليزية',
    curbOptionNameArLang: 'إسم خيار الارتداد في اللغة العربية',
    areaNameEnLang: 'إسم المنطقة في اللغة الإنجليزية',
    areaNameArLang: 'إسم المنطة في اللغة العربية',
    areaNameAr: 'إسم المنطقة - العربية',
    areaNameEn: 'إسم المنطقة - الإنجليزية',
    propertyTypeNameArLang: 'إسم نوع العقار في اللغة العربية',
    propertyTypeNameEnLang: 'إسم نوع العقار في اللغة الانجليزية',
    propertCategoryNameEnLang: 'إسم فئة العقار في اللغة الانجليزية',
    propertCategoryNameArLang: 'إسم فئة العقار في اللغة العربية',
    multiCurb: 'متعدد الارتداد',
    multiSelection: 'اختيار متعدد',
    isSeaView: 'مطل للبحر',
    customUpdatedAt: 'Custom Updated Date',
    pullData: 'Pull Data',
    url: 'URL',
    from: 'من',
    to: 'الى',
    weight: 'Weight',
    arabicName: 'الإسم بالعربية',
    arabicValue: 'القيمة بالعربية',
    englishValue: 'القيمة بالانجليزية',
    arabicSize: 'المساحة بالعربية',
    englishSize: 'المساحة بالانجليزية',
    englishQuestion: 'السؤال بالانجليزية',
    englishAnswer: 'الاجابة بالعربية',
    arabicQuestion: 'السؤال بالعربية',
    arabicAnswer: 'الاجابة بالعربية',
    textInArabic: 'النص بالعربية',
    textInEnglish: 'النص بالانجليزية',
    englishName: 'الإسم بالانجليزية',
    phoneNumber: 'رقم الهاتف',
    accountType: 'نوع الحساب',
    cancel: 'إلغاء',
    sorting: 'الترتيب',
    role: 'الدور',
    approved: 'موافق عليه',
    rejected: 'مرفوض',
    contactPerson: 'اسم جهة الاتصال',
    contactPhone: 'هاتف جهة الاتصال',
    contactPersonName: 'اسم جهة الاتصال',
    contactPersonPhone: 'هاتف جهة الاتصال',
    appraiserPhone: 'هاتف المقيم',
    bankAccountDetails: 'تفاصيل الحساب البنكي',
    appraiserDetails: 'تفاصيل المقيم',
    auctionDetails: 'تفاصيل المزاد',
    appraiserBanks: 'بنوك المقيم',
    appraiserPrices: 'اسعار المقيم',
    auctionProperties: 'عقارات المزاد',
    banks: 'البنوك',
    savingBanksNote: 'حفظ معلومات المقيم مطلوب قبل قيامك باضافة البنوك.',
    savingAuctionAttachmentNote:
      'حفظ معلومات المزاد مطلوب قبل قيامك باضافة الملحقات.',
    savingPricesNote: 'حفظ معلومات المقيم مطلوب قبل قيامك باضافة الاسعار.',
    savingServiceNote:
      'Saving package details is required before proceeding to adding its services.',
    noResultsFound: 'لا يوجد نتائج',
    propertyType: 'نوع العقار',
    building: 'بناء',
    percentage: 'نسبة مئوية',
    nonCustumerPercentage: 'نسبة غير العملاء',
    land: 'أرض',
    organiserName: 'اسم المنظم',
    date: 'التاريخ',
    time: 'الوقت',
    venue: 'المكان',
    type: 'النوع',
    numberOfProperties: 'عدد الملكيات',
    filterOptions: 'خيارات البحث',
    today: 'اليوم',
    historical: 'تاريخي',
    upcoming: 'القادمة',
    reset: 'إعادة تعيين',
    apply: 'إرسال',
    filter: 'فلتر',
    addNewAuction: 'اضافة مزاد جديد',
    organizer: 'المنظم',
    dateAndTime: 'التاريخ والوقت',
    eventDateAndTime: 'تاريخ ووقت الحدث',
    auctionVideo: 'فيديو للمزاد',
    organizerLogo: 'شعار المنظم',
    logo: 'الشعار',
    location: 'الموقع',
    loanRequest: 'طلب قرض',
    certifiedCheckRequest: 'Certified Check Request',
    block: 'الحي',
    size: 'المساحة',
    propertyBlock: 'حي العقار',
    propertyPlot: 'قطعة العقار',
    propertySize: 'مساحة العقار',
    startingPrice: 'سعر مبدئي',
    salePrice: 'سعر البيع',
    additionalInfoEn: 'معلومات اضافية (انجليزية)',
    additionalInfoInEn: 'معلومات اضافية في الانجليزية',
    factorOptionIve: 'قيمة خيار العامل',
    additionalInfoInAr: 'معلومات اضافية في العربية',
    additionalInfoAr: 'معلومات اضافية (انجليزية)',
    startingPricePerSqm: 'السعر المبدئي \\ متر مربع',
    startingPricePerSquareMeter: 'السعر المبدئي لكل متر مربع',
    salePricePerSqm: 'سعر البيع \\ متر مربع',
    salePricePerSquareMeter: 'سعر البيع لكل متر مربع',
    propertyStatus: 'حالة العقار',
    area: 'المنطقة',
    plot: 'قطعة',
    percentagePremiumPaid: 'Premium % Paid',
    propertyAttachments: 'مرفقات العقار',
    auctionAttachments: 'مرفقات المزاد',
    video: 'فيديو',
    updateCceGroup: 'تحديث مجموعة حسبة البناء',
    createNewCceGroup: 'انشاء مجموعة حسبة البناء',
    addNewCceGroup: 'اضافة مجموعة جديد',
    CceGroupsList: 'قائمة مجموعات حسبة تكلفة البناء',
    titleAr: 'العنوان بالعربي',
    titleEn: 'العنوان بالانجليزي',
    cceGroupsUpdating: 'تحديث مجوعات حسبة البناء',
    cce: 'حسبة البناء',
    updateCceCategorie: 'تحديث فئة حسبة البناء',
    createNewCceCategorie: 'انشاء فئة حسبة البناء',
    addNewCceCategorie: 'اضافة فئة جديد',
    CceCategorieList: 'قائمة فئات حسبة تكلفة البناء',
    isDependQuality: ' تعتمد الجودة',
    savingSubCategoryOptionsNote:
      'حفظ معلومات الفئة مطلوب قبل قيامك باضافة التفاصيل.',
    cceCategory: 'فئة حسبة البناء',
    cceCategoryTooltip: 'فئات حسبة البناء',
    selectCceCategory: 'اختر الفئة',
    multiplier: 'المضاعف',
    defaultPrice: 'السعر الافتراضي',
    sort: 'الترتيب',
    subCategoriesOptions: 'الفئات الفرعية',
    CceSubCategoriesList: 'قائمة الفئات الفرعية',
    updateCceSubCategorie: 'تحديث فئة فرعية جديدة',
    addNewCceSubCategorie: 'إضافة فئة فرعية جديدة',
    categoryName: 'اسم الفئة',
    SUCCESSFULLY_CREATED_CCE_GROUP: 'تم إنشاء مجموعة بنجاح',
    cceGroupsCreating: 'انشاء مجموعة جديد',
    SUCCESSFULLY_UPDATED_CCE_GROUP: 'تم تحديث المجموعة بنجاح!',
    cceGroupsListing: 'قائمة مجموعة حسبة البناء',
    cceGroup: 'CCE Group',
    cceGroupTooltip: 'CCE Group Tooltip',
    selectCceGroup: 'Select Cce Group',
    SUCCESSFULLY_CREATED_CCE_CATEGORIE: 'Successfully created a CCE Categorie!',
    cceCategoriesCreating: 'CCE Categories Creating',
    cceCategoriesUpdating: 'CCE Categories Updating',
    SUCCESSFULLY_UPDATED_CCE_CATEGORIE: 'Successfully updated a CCE Categorie!',
    CceCategoriesList: 'CCE Categories List',
    cceCategoriesListing: 'CCE Categories Listing',
    groupName: 'Group Name',
    RegisteredTrades: 'الصفقات المسجلة',
    createNewRegisteredTrades: 'انشاء صفقات المسجلة',
    updateRegisteredTrades: 'تحديث الصفقات المسجلة',
    RegisteredListing: 'قائمة الصفقات المسجلة',
    pricePerM2: 'السعر لكل متر',
    notes: 'ملاحظات',
    transactionDate: 'تاريخ الصفقة',
    RegisteredTradesScraping: 'قائمة الصفقات المسجلة (موقع الوزارة)',
    transactionNumber: 'رقم المعاملة',
    areas: 'المناطق',
    RegisteredTradesListing: 'Registered Trades Listing',
    descriptionAr: 'Description Arabic',
    descriptionEn: 'Description English',
    notesAr: 'Notes Arabic',
    notesEn: 'Notes English',
    registeredTradesUpdating: 'Registered Trades Updating',
    TRANSACTION_NUMBER_IS_REQUIRED_FIELD:
      'Transaction number is required field',
    SIZE_IS_REQUIRED_FIELD: 'Size is required field',
    PRICE_IS_REQUIRED_FIELD: 'Price is required field',
    PRICE_PER_M2_IS_REQUIRED_FIELD: 'Price per m2 is required field',
    PROPERTY_TYPE_IS_REQUIRED_FIELD: 'Property type is required field',
    SUCCESSFULLY_UPDATED_REGISTERED_TRADE:
      'Successfully updated registered trade',
    SUCCESSFULLY_CREATED_REGISTERED_TRADE:
      'sSuccessfully created registered trade',
    pulleRegisteredTradesScraping: 'Pull Registered Trades Scraping',
    selectDate: 'Select Date',
    SUCCESSFULLY_PULL_REGISTERED_TRADE_SCRAPING:
      'Successfully pull registered trade scraping',
    SUCCESSFULLY_UPDATED_REGISTERED_TRADE_SCRAPING:
      'Successfully updated registered trade scraping',
    SUCCESSFULLY_UPDATED_LOAN_FUNDS_REQUEST:
      'Successfully updated loan fund request',
    SUCCESSFULLY_UPDATED_AD_SERVICE: 'Successfully updated ad service',
    SUCCESSFULLY_UPDATED_HEADER_DESCRIPTION:
      'Successfully updated header description',
    SUCCESSFULLY_UPDATED_DISCLAIMER_DESCRIPTION:
      'Successfully updated disclaimer description',
    SUCCESSFULLY_ACTIVEATED: 'Successfully Activated',
    SUCCESSFULLY_INACTIVATED: 'Successfully Inactivated',
    id: 'S.NO',
    publishName: 'Publish Name',
    publishMobile: 'Publish Mobile',
    adsType: 'Ads Type',
    publishedAt: 'Published At',
    isPaid: 'Paid',
    attachmentImages: 'Ads Images',
    attachmentVideos: 'Ads Video',
    attachmentVideosAuction: 'Auction Video',
    docAttachment: 'Ads Documents',
    adsDetails: 'Ads Details',
    adOverview: 'Ad Overview',
    changeAdsRequestStatus: 'Change Ads Status',
    adsUpdating: 'Ads Updating',
    SUCCESSFULLY_UPDATED_ADS_REQUEST: 'Successfully Updated Ads Status',
    venueEn: 'إسم المكان - الانجليزية',
    venueAr: 'إسم المكان - العربية',
    coordinates: 'إحداثيات',
    latitude: 'خط العرض',
    longitude: 'خط الطول',
    LATITUDE_IS_REQUIRED_FIELD: 'حقل خط العرض بالانجليزية مطلوب',
    LONGITUDE_IS_REQUIRED_FIELD: 'حقل خط الطول بالانجليزية مطلوب',
    organizerPhone: 'رقم هاتف المنظم',
    phone: 'الهاتف',
    createdAt: 'أنشئت في',
    updatedAt: 'تحدثت في',
    LoansFundsRequest: 'Loans Funds Request',
    loanFundsRequestOverview: 'Loans Funds Request Overview',
    propertyValue: 'Property Value',
    loanDuration: 'Loan Duration',
    annualProfit: 'AnnualProfit',
    totalPurchasePrice: 'Total Purchase Price',
    bankProfit: 'Bank Profit',
    downPayment: 'Down Payment',
    gracePeriod: 'فترة السماح',
    loanFundRequestOverview: 'Loan Fund Request Overview',
    loanAmount: 'Loan Amount',
    repaymentPeriod: 'فترة السداد',
    nonBoubyanCustomer: 'عميل لغير بوبيان',
    boubyanCustomer: 'عميل بوبيان',
    priceFrom: 'Price From',
    priceTo: 'Price To',
    residentials: 'خاص',
    commercial: 'تجاري',
    propertySoldStatus: 'Property Sold Status',
    adsServices: 'Ads Services',
    arabicTitle: 'العنوان بالعربية',
    englishTitle: 'العنوان بالانجليزية',
    createNewAdService: 'Create new Ads Service',
    adsDuration: 'Ads Duration',
    adsDurationDay: 'Ads duration (days)',
    adServiceTitleArlang: 'Ads service title-Arabic ',
    adServiceTitleEnlang: 'Ads service title - English',
    adsServiceUpdating: 'Update Ads Service',
    subscriptions: 'Subscriptions',
    adsServiceCreating: 'Ad service Creating',
    adPackageAdsCreating: 'Package Ad Creating',
    adPackagesDetails: 'Package Details',
    adPackageTitleAr: 'Package Title in Arabic Language',
    adPackageTitleEn: 'Package Title in English Language',
    adsPackagesUpdating: 'Update Ads Package',
    bannerUpdating: 'Banner Updating',
    adsPackages: 'Ads Packages',
    newsListing: 'قائمة الاخبار',
    createNewAdPackage: 'Create New Ads Package',
    packageServices: 'Package Services',
    instantValueEstimatorPanel: 'لوحة مقدر القيمة الفورية',
    instantValueCloningPanel: 'لوحة نسخ القيمة الفورية',
    ValueSizePanel: ' Value Size Panel',
    ValueFactorBathroomPanel: ' Value Factor Bathroom Panel',
    ValueFactorOptionPanel: ' Value Factor Option Panel',
    ValueFactorGeneralspecsPanel: ' Value Factor General specs Panel',
    ValueFactorLocationspecsPanel: ' Value Factor Location specs Panel',
    ValueFactorQualityPanel: ' Value Factor Quality Panel',

    qualityGroupsPanel: 'لوحة مجموعات الجودة',
    factors: 'عوامل',
    totalValues: 'عدد القيم',
    factorOption: 'خيار العوامل',
    value: 'القيمة',
    note: 'ملاحظة',
    noFactorOptionsLeft: 'لا يوجد خيارات متاحة لهذا العامل ليتم اضافتها',
    addNewValue: 'أضف قيمة جديدة',
    addNewQuestion: 'اضافة سؤال جديد',
    bannerLocation: 'Banner Location',
    bannerAction: 'Banner Action',
    externalLink: 'External Link',
    bannerType: 'Banner Type',
    merchant: 'Merchant',
    entityId: 'entity ID',
    bannerUrl: 'Banner URL',
    bannerImage: 'Banner Image',
    bannerInfo: 'Banner Info',
    auctionStatus: 'Auction Status',
    PHONE_MIN_8: 'minimum length is 8 Number',
    PHONE_MAX_11: 'maximum length is 11 Number',
    areaCompare: 'مقارنة المناطق',
    areaClone: 'نسخ المنطقة',
    startComparingAreas: 'بدء مقارنة مناطق',
    currentVideoUrl: 'رابط الفيديو الحالي',
    score: 'مجموع النقاط',
    appartmentSize: 'Appartment Size',
    Generalspecs: 'General specs Values',
    Locationspecs: 'Location specs Values',
    propertyService: 'Property Service Values',
    ireSizesValues: 'Ire Sizes Values',
    selected: 'مختارة',
    approveSelected: 'موافقة المختارة',
    freeAd: 'Free AD',
    paidAd: 'Paid AD',
    anotherCompare: 'Another Compare',
    setAsDefault: 'Set As Default',
    numRooms: 'Number of Room',
    numBathrooms: 'Number of Bathrooms',
    adsPackagesTitle: 'Ad Package',
    additionalInfo: 'Additional Info',
    exportIveArea: 'Export Ive Area',
    approveAllAds: 'Approve All Ads',
    headerDescription: 'Header Description',
    headerDescriptionUpdating: 'Header Description Updating',
    disclaimer: 'Disclaimer',
    disclaimerUpdating: 'Disclaimer Updating',
    adminAccess: 'Admin access',
    actionDescription: 'Action description',
    timeStamp: 'Time stamp',
    exportLogsReports: 'Export Logs Reports',
    bulkUpdate: 'تحديث متعدد',
    deactivate: 'تعطيل',
    login: 'تسجيل الدخول',
    logout: 'تسجيل الخروج',
    success: 'نجاح',
    failed: 'فشل',
    selectAction: 'Select a Action',
    expiryBanner: 'Expiry Banner',
    enableGracePeriod: 'تفعيل فترة السماح',
    disableGracePeriod: 'تعطيل فترة السماح',
    isRegisteredTrades: 'الصفقات المسجلة',
    isAd: 'الاعلانات',
    isAuction: 'المزادات',
    SUCCESSFULLY_UPDATED_PROPERTY_CATEGORY: 'تم تحديث فئة العقار بنجاح',
    SUCCESSFULLY_CREATED_PROPERTY_CATEGORY: 'تم انشاء فئة العقار بنجاح',
    selectPropertyCategory: 'اختر فئة العقار',
    propertyCategory: 'فئة العقار',
    PROPERTY_CATEGORY_IS_REQUIRED_FIELD: 'فئة العقار مطلوب',
    SUCCESSFULLY_ADDED_IRE_SIZE_OPTIONS: 'تم اضافة خيارات ابعاد العقار بنجاح',
    savingIreAvailableSizesNote: 'حفظ ملاحظات ابعاد العقار',
    isBaseSize: 'ابعاد اساسي',
    IrePropertyAge: 'عمر العقار',
    savingIreAvailablePropertyAgeNote: 'حفظ ملاحظات عمر العقار',
    ireAreasCreating: 'انشاء مناطق العقار',
    areasIreValuesUpdating: 'تحديث قيم مناطق العقار',
    updateIreAreaValue: 'تحديث قيمة منطقة العقار',
    IreAvailableSizes: 'ابعاد العقار',
    MUST_ADDED_IRE_BASE_SIZE_OPTIONS: 'يرجى اختيار مساحة اساسية',
    ONE_IRE_BASE_SIZE_OPTIONS: 'يرجى اختيار مساحة اساسية واحدة فقط',
    UserInfo: 'معلومات المستخدم',
    firstIncreasePercentage: 'First Increase Percentage',
    firstIncreasePercentageToolTip:
      'First Increase Percentage For GOOD Quality',
    secondIncreasePercentage: 'Second Increase Percentage',
    secondIncreasePercentageToolTip:
      'Second Increase Percentage For VERY GOOD Quality',
    decreasedPercentage: ' Decreased Percentage',
    SUCCESSFULLY_CREATED: 'Successfully Created',
    SUCCESSFULLY_UPDATED: 'Successfully Updated',
    updateIreQualities: 'Update Ire Qualities',
    ireQualitiesListing: 'Ire Qualities Listing',
    irePropertyTypeListing: 'ire Property Type Listing',
    updateIrePropertyType: 'update Ire Property Type',
    ireInvestmentPropertyAgeListing: 'ire Property Age',
    updateIrePropertyAge: 'update Ire Property Age',
    ireLocationsListing: 'ire Locations Listing',
    updateIreLocations: 'update Ire Locations',
    ireInvestmentConfigListing: 'Ire Investment Config Listing',
    updateIreInvestmentConfig: 'update Ire Investment Config',
    PERCENTAGE_IS_REQUIRED_FIELD: 'Percentage is required',
    NumOfRegisteredTrades: 'Number Of Registered Trades : ',
    askSeller: 'Ask Seller',
    priceNotAvailable: 'Price Not Available',
    adDetails: 'Ad Details',
    addNewAds: 'Create New Ad',
    AdInfo: 'Ad Information',
    AD_TYPE__IS_REQUIRED_FIELD: 'Ad Type Is Required Field',
    PUBLISH_NAME_IS_REQUIRED_FIELD: 'Publish Name Is Required Field',
    PACKAGE_IS_REQUIRED_FIELD: 'Package Is Required Field',
    adImagesAttachments: 'Ad Images',
    savingAdAttachmentNote: 'Saving Ad Attachment After Craete Ad',
    adVideoAttachments: 'Ad Video',
    adDocAttachments: 'Ad Document',
    adsUpdate: 'Ad Service Update',
    SUCCESSFULLY_UPDATED_AD: 'Successfully Updated Ad',
    SUCCESSFULLY_UPLOADED_AD_IMAGES: 'Successfully Upload Ad Images',
    SUCCESSFULLY_UPLOADED_AD_VIDEO: 'Successfully Upload Ad Video',
    SUCCESSFULLY_UPLOADED_AD_DOC: 'Successfully Upload Ad Document',
    SUCCESSFULLY_REMOVE_AD_IMAGE: 'Successfully Remove Ad Image',
    updateAd: 'Update Ad',
    baseValue: 'Base Value',
    thirdDecreasedPercentage: 'Third Decreased Percentage',
    fourthDecreasedPercentage: 'Fourth Decreased Percentage',
    thirdDecreasedPercentageToolTip: 'Third Percent',
    fourthDecreasedPercentageToolTip: 'Fourth Percent',
    selectePropertyCategory: 'اختر فئة العقار',

    FIRST_DESC_IS_REQUIRED_FIELD: 'First Decreased Is Required Field',
    SECOND_DESC_IS_REQUIRED_FIELD: 'Second Decreased Is Required Field',
    THIRD_DESC_IS_REQUIRED_FIELD: 'Third Decreased Is Required Field',
    FOURTH_DESC_IS_REQUIRED_FIELD: 'Fourth Decreased Is Required Field',
    FIRST_BASE_VAL_IS_REQUIRED_FIELD: 'First Base Value Is Required Field',
    SECOND_BASE_VAL_IS_REQUIRED_FIELD: 'Second Base Value Is Required Field',
    THIRD_BASE_VAL_IS_REQUIRED_FIELD: 'Third Base Value Is Required Field',
    FOURTH_BASE_VAL_IS_REQUIRED_FIELD: 'Fourth Base Value Is Required Field',
    firstBaseValue: 'First Base Value',
    secondBaseValue: 'Second Base Value',
    thirdBaseValue: 'Third Base Value',
    fourthBaseValue: 'Fourth Base Value',
    sizeQualityConfig: 'Size Quality Config',
    LeadID: 'Lead Id *From BBYN',
    isSent: 'Sent To BBYN',
    FinanceFundsRequest: 'Finance Funds Requests',
    financesFundsRequest: 'Finance Funds Requests',
    financeFundsRequestOverview: 'finance Funds Request Overview',
    siteUrl: 'Alhisba Site URL',
    resStatus: 'response Status *From BBYN',
    resStatusText: 'response Status Text *From BBYN',
    leadCode: 'lead Code *From BBYN',
    UnSelectAreas: 'المناطق المستثناة',
    registeredTradesTopAreasReports: 'Trades Top Areas Reports',
    registeredTradesReports: 'Registered Trades Reports',
    AdsPerQuarterReports: 'Ads Per Quarter Reports',
    registeredTradesTopsReports: 'Registered Trades Tops Reports',
    NumOfLowestRegisteredTrades: 'Num Of Lowest Trades',
    NumOfTopRegisteredTrades: 'Num Of Top Trades',
    registeredTradesPerQuarterReports: 'Registered Trades Per Quarter Reports',
    RegisteredTradesTotalsReports: 'Registered Trades Gov Totals Reports',
    SUCCESSFULLY_GET_REPORTS_DATA: 'SUCCESSFULLY GET REPORTS DATA',
    pullRegisteredTrades: 'Pull Registered Trades',
    importRegisteredTrades: 'Import Registered Trades',
    SUCCESSFULLY_UPLOAD_REGISTERED_TRADE:
      'Successfully Upload Registered Trades',
    rejectSelected: 'reject Selected',
  },
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
