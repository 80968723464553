import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface LinksObject {
  id: number;
  titleEn: string;
  titleAr: string;
  externalLink: string;
  icon: string;
  sort: number;
}

@Module
export default class LinksModule extends VuexModule {
  links = [] as Array<LinksObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get Links list
   * @returns Array<LinksObject>
   */
  get linksList(): Array<LinksObject> {
    return this.links;
  }

  /**
   * Get total items  
   * @returns number
   */
  get linksListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_LINKS](data) {
    this.links = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
  }

  @Mutation
  [Mutations.SET_ALL_LINKS](data) {
    this.links = data.data;
  }

  @Mutation
  [Mutations.SET_LINKS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_LINKS_LIST]() {
    this.links = [] as Array<LinksObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_LINKS_STORE]() {
    this.context.commit(Mutations.RESET_LINKS_LIST);
  }

  @Action
  [Actions.CREATE_LINKS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/links', data)
      .then(() => {
        this.context.commit(Mutations.RESET_LINKS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_LINK](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/links', id)
      .then(() => {
        this.context.commit(Mutations.RESET_LINKS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_LINK]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/links', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_LINKS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_LINKS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_LINKS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ONE_LINK](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/links', id);
  }

  @Action
  [Actions.GET_LINKS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/links', {
      params: { page: this.currentPage, limit: 10 },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LINKS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  async [Actions.GET_ALL_LINKS]() {
    ApiService.setAuthorizationHeader();
    try {
      const { data } = await ApiService.query('/links/all', {});
      this.context.commit(Mutations.SET_ALL_LINKS, data);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  @Action
  [Actions.UPLOAD_LINK_ICON]({ data, linkId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/links/uploadIcon/${linkId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
